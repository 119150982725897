import arrow from 'images/arrow.svg';
import logo from 'images/logo.svg';
import './RiseHeader.css';

type Props = {
  backButton?: boolean;
  onBackClick?: () => void;
};
export function RiseHeader({backButton = true, onBackClick}: Props) {
  return (
    <div className="rise-header">
      <div className="rise-header-left">
        {backButton && onBackClick ? <img src={arrow} alt="Back" onClick={onBackClick} /> : null}
      </div>
      <div className="rise-header-center">
        <img src={logo} alt="Rise" />
      </div>
      <div className="rise-header-right" />
    </div>
  );
}

import React from 'react';
import {RiseInterstitialProps} from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialBase';
import 'screens/RiseSurvey/components/RiseInterstitialTemplate/interstitialBase.css';
import RiseSurveyInterstitialControls from '../../components/RiseSurveyControls/RiseSurveyInterstitialControls';
import hero from './hero.png';

const SportsInterstitial: React.FC<RiseInterstitialProps> = () => {
  return (
    <div className="interstitial centerText">
      <img src={hero} style={{width: '100%'}} alt="NFL" />
      <RiseSurveyInterstitialControls />
    </div>
  );
};
export default SportsInterstitial;

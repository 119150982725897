import React from 'react';
import {CompleteButton} from './CompleteButton';
import {NextButton} from './NextButton';
import {SkipButton} from './SkipButton';

export type RiseSurveyControlsProps = {
  mode: 'last_page' | 'question' | 'interstitial';
};

export const RiseSurveyControls: React.FC<RiseSurveyControlsProps> = ({mode}) => {
  if (mode === 'interstitial') {
    return null;
  }
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '16px',
      }}
    >
      {mode === 'last_page' ? (
        <CompleteButton />
      ) : (
        <>
          <NextButton /> <SkipButton />
        </>
      )}
    </div>
  );
};

export default RiseSurveyControls;

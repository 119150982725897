import React from 'react';

interface CustomProgressBarProps {
  currentQuestionIndex: number;
  totalQuestions: number;
}

export const SegmentedProgressBar: React.FC<CustomProgressBarProps> = ({currentQuestionIndex, totalQuestions}) => {
  const progressPct = currentQuestionIndex / totalQuestions;
  const progress = Math.round(progressPct * totalQuestions);

  return (
    <div style={{display: 'flex', width: '100%', height: '4px'}}>
      {Array.from({length: totalQuestions}).map((_, index, arr) => {
        const segmentEnd = (index + 1) % 3 === 0;
        const segmentStart = (index + 1) % 3 === 1;
        const last = index + 1 === arr.length;
        return (
          <div
            key={index}
            style={{
              borderTopLeftRadius: segmentStart ? '4px' : '0',
              borderBottomLeftRadius: segmentStart ? '4px' : '0',
              borderTopRightRadius: segmentEnd || last ? '4px' : '0',
              borderBottomRightRadius: segmentEnd || last ? '4px' : '0',
              flex: 1,
              backgroundColor: index < progress ? 'black' : 'rgba(0,0,0,0.1)',
              overflow: 'hidden',
              marginRight: segmentEnd && !last ? '8px' : '0',
            }}
          />
        );
      })}
    </div>
  );
};

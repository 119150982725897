import React from 'react';
import 'screens/RiseSurvey/components/RiseInterstitialTemplate/interstitialBase.css';
import {RiseInterstitialProps} from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialBase';
import RiseSurveyInterstitialControls from 'screens/RiseSurvey/components/RiseSurveyControls/RiseSurveyInterstitialControls';

const Star = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 107273">
      <path
        id="Star 4"
        d="M10.5001 1.6665L12.0675 7.84246L18.4256 7.4247L13.0362 10.8239L15.3983 16.7416L10.5001 12.6665L5.60187 16.7416L7.96393 10.8239L2.57461 7.4247L8.93265 7.84246L10.5001 1.6665Z"
        fill="#e7b63c"
      ></path>
    </g>
  </svg>
);
const BoostedEnergyInterstitial: React.FC<RiseInterstitialProps> = () => {
  return (
    <div className="interstitial">
      <h1>Boosted Energy</h1>
      <div
        className="card-container"
        style={{display: 'flex', flexDirection: 'column', paddingLeft: '3px', paddingRight: '3px'}}
      >
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{gap: '4px'}}>
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </div>
          <div style={{paddingLeft: '12px'}} className="card-title">
            320K+ positive reviews
          </div>
        </div>
        <div
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '10px',
            fontSize: '17px',
            color: 'black',
            textAlign: 'center',
          }}
        >
          "The personalized sleep plan transformed my energy levels and productivity remarkably!"
          <br />
          <br />
          <span style={{fontWeight: 'bold'}}>Emily S.</span>
        </div>
      </div>
      <div>
        <p style={{paddingTop: '12px', paddingLeft: '12px', paddingRight: '12px', fontSize: '15px'}}>
          *People who used Rise notice improvements in energy in just in a week.
        </p>
      </div>
      <RiseSurveyInterstitialControls />
    </div>
  );
};

export default BoostedEnergyInterstitial;

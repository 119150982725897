// import * as Sentry from '@sentry/react';

/**
 * Returns the value of an environment variable.
 * Will report an error to sentry if it isn't found and return undefined.
 * This make testing modules easier.
 */

export type EnvKey = 'REACT_APP_ENV' | 'REACT_APP_STRIPE_KEY';
type EnvironmentVariable<Key> = Key extends 'REACT_APP_ENV'
  ? 'test' | 'production'
  : Key extends 'REACT_APP_STRIPE_KEY'
    ? string
    : undefined;

function getVar<Key extends EnvKey>(key: Key) {
  const value = process.env[key];
  if (value === undefined) {
    // Sentry.captureException(
    //   new Error(`Missing required environment variable: ${key}`)
    // );
  }
  return value as EnvironmentVariable<Key>;
}

const isProduction = getVar('REACT_APP_ENV') === 'production';

const isTest = (getVar('REACT_APP_ENV') ?? 'test') === 'test';

const isRunningInJest = () => {
  return process.env.JEST_WORKER_ID !== undefined;
};

const Environment = {
  getVar,
  isProduction,
  isTest,
  isRunningInJest,
};
export default Environment;

import {addWeeks, format} from 'date-fns';
import img_editors_choice from 'images/editors-choice.png';
import img_female_hero from 'images/female-user.jpg';
import img_thumbs_up from 'images/good-hands.webp';
import img_graph_multiple from 'images/graph-multiple.png';
import img_graph_single from 'images/graph-single.png';
import img_male_hero from 'images/male-user.jpg';
import img_rise_twinkles from 'images/rise-logo-with-twinkles.png';
import img_sleep_debt_accumulation from 'images/sleep-debt-accumlation-chart.png';
import img_sleep_debt_high from 'images/sleep-debt-high.png';
import img_sleep_debt_low from 'images/sleep-debt-low.png';
import img_sleep_debt_moderate from 'images/sleep-debt-moderate.png';
import img_sleep_debt_reduction_chart from 'images/sleep-debt-reduction-chart.png';
import img_sleep_twinkles from 'images/sleep-icon-with-twinkles.png';
import RiseInterstitialTemplate from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialTemplate';
import AnalyzeInterstitial from 'screens/RiseSurvey/interstitials/AnalyzeInterstitial/AnalyzeInterstitial';
import BoostedEnergyInterstitial from 'screens/RiseSurvey/interstitials/BoostedEnergyInterstitial/BoostedEnergyInterstitial';
import ProgressChart from 'screens/RiseSurvey/interstitials/ProgressInterstitial/ProgressChart';
import SportsInterstitial from 'screens/RiseSurvey/interstitials/SportsInterstitial/SportsInterstitial';
import {surveyInstance} from 'screens/RiseSurvey/RiseSurvey';
import {survey_config_base} from 'screens/RiseSurvey/utils/__survey_config_base';
import {ifExpression} from 'screens/RiseSurvey/utils/ifExpression';
import {interstitial} from 'screens/RiseSurvey/utils/interstitial';
import {radioPage} from 'screens/RiseSurvey/utils/page';
import {randomize} from 'screens/RiseSurvey/utils/randomize';
import {switchExpression} from 'screens/RiseSurvey/utils/switchExpression';
import {RiseInterstitialPage, RiseSurveyConfig, RiseSurveyPage} from 'types';

export const getSleepDebtZoneFromMidpointOffsets = (
  bedtimeMidpointMidnightOffset: string,
  wakeMidpointMidnightOffset: string,
) => {
  // We if Skipped or "Varies" the value is an empty string
  if (bedtimeMidpointMidnightOffset === '' || wakeMidpointMidnightOffset === '') {
    return 'moderate';
  }
  const bedtime = parseFloat(bedtimeMidpointMidnightOffset);
  const wake = parseFloat(wakeMidpointMidnightOffset);
  /** From kellie
   * Calculate sleep duration from midpoints
   * Assume users need 8.5h
   * IF duration 8+ -> LOW
   * IF duration 7.5h -> MODERATE
   * IF <7.5h -> HIGH
   * IF Null => Moderate
   */
  const sleepDuration = bedtime + wake;
  if (sleepDuration >= 8) {
    return 'low';
  } else if (sleepDuration >= 7.5 || sleepDuration === Infinity) {
    return 'moderate';
  }
  return 'high';
};

const getSleepDebtColor = (sleepDebtZone: string) => {
  switch (sleepDebtZone) {
    case 'high':
      return '#E51542';
    case 'low':
      return '#008568';
    case 'moderate':
    default:
      return '#B85C00';
  }
};
const getSleepDebtChartImage = (sleepDebtZone: string) => {
  switch (sleepDebtZone) {
    case 'high':
      return img_sleep_debt_high;
    case 'low':
      return img_sleep_debt_low;
    case 'moderate':
    default:
      return img_sleep_debt_moderate;
  }
};

const calculatedValues = [
  {
    name: 'gendered_noun',
    expression: switchExpression({
      switch: '{gender}',
      cases: {
        Male: 'man',
        Female: 'woman',
        _default: 'person',
      },
    }),
  },
  {
    name: 'gendered_noun_plural',
    expression: switchExpression({
      switch: '{gender}',
      cases: {
        Male: 'men',
        Female: 'women',
        _default: 'people',
      },
    }),
  },
  {
    name: 'in_your_age_group',
    expression: switchExpression({
      switch: '{age}',
      cases: {
        'Under 18': 'in your teens',
        '18-23': ' in your late teens or early 20s',
        '24-29': ' in your 20s',
        '30-39': ' in your 30s',
        '40-49': ' in your 40s',
        '50-59': ' in your 50s',
        '60+': ' over 60',
        _default: '',
      },
    }),
  },
  {
    name: 'in_their_age_group',
    expression: switchExpression({
      switch: '{age}',
      cases: {
        'Under 18': 'in their teens',
        '18-23': ' in their late teens or early 20s',
        '24-29': ' in their 20s',
        '30-39': ' in their 30s',
        '40-49': ' in their 40s',
        '50-59': ' in their 50s',
        '60+': ' over 60',
        _default: '',
      },
    }),
  },
  {
    name: 'combined_customized_phrase',
    expression: ifExpression({
      if: "{age} empty && ({gender} empty || {gendered_noun} == 'person')",
      then: 'You',
      else: `'As a '+{gendered_noun}+{in_your_age_group}+', you'`,
    }),
  },
  {
    name: 'goal_phrase',
    expression: switchExpression({
      switch: '{goal}',
      cases: {
        'Fall asleep faster': 'fall asleep faster',
        'Stay asleep throughout the night': 'stay asleep all night',
        'Improve sleep quality': 'improve your sleep quality',
        'Wake up refreshed': 'wake refreshed',
        'Reduce sleep debt': 'reduce sleep debt',
        _default: 'improve your sleep',
      },
    }),
  },
  {
    name: 'goal_success_phrase',
    expression: switchExpression({
      switch: '{goal}',
      cases: {
        'Fall asleep faster': 'falling asleep faster',
        'Stay asleep throughout the night': 'staying asleep all night',
        'Improve sleep quality': 'improving their sleep quality',
        'Wake up refreshed': 'waking up refreshed',
        'Reduce sleep debt': 'reducing Sleep Debt',
        _default: 'improving their sleep ',
      },
    }),
  },
  {
    name: 'bedtime_midpoint_midnight_offset',
    expression: switchExpression({
      switch: '{bedtime_routines}',
      cases: {
        'Before 10 PM': '2.5',
        '10-11 PM': '1.5',
        '11 PM-12 AM': '0.5',
        '12-1 AM': '-0.5',
        'After 1 AM': '-1.5',
        Varied: '',
        _default: '',
      },
    }),
  },
  {
    name: 'wake_midpoint_midnight_offset',
    expression: switchExpression({
      switch: '{sleep_patterns}',
      cases: {
        'Before 6 AM': '5.5',
        '6-7 AM': '6.5',
        '7-8 AM': '7.5',
        '8-9 AM': '8.5',
        '9-10 AM': '9.5',
        'After 10 AM': '10',
        _default: '',
      },
    }),
  },
];

export const survey_config_web_testimonial_credibility_oct_2024: RiseSurveyConfig = {
  // Add the images to preload.  These will be preloaded when the survey is loaded.
  // this is optional.
  preloadImages: [
    img_thumbs_up,
    img_editors_choice,
    img_female_hero,
    img_male_hero,
    img_rise_twinkles,
    img_sleep_twinkles,
  ],
  ...survey_config_base,
  title: 'Web Testimonial Credibility Oct 2024',
  calculatedValues,
  pages: [
    radioPage({
      name: 'age',
      title: 'Sleep needs vary by age. How old are you?',
      choices: ['18-23', '24-29', '30-39', '40-49', '50-59', '60+'],
      delay: 'random',
    }),
    radioPage({
      name: 'gender',
      title: 'Select your gender.',
      choices: randomize(['Male', 'Female', 'Non-Binary'], ['Prefer not to say']),
      delay: 'random',
    }),
    radioPage({
      name: 'current_quality',
      title:
        'Research shows that sleep quality impacts daily energy levels. How would you rate your current sleep quality?',
      choices: randomize([
        'I sleep like a baby',
        'I toss and turn a bit',
        'I struggle to fall asleep',
        'I wake up multiple times at night',
        'I feel exhausted in the morning',
      ]),
      delay: 'random',
    }),
    radioPage({
      name: 'goal',
      title:
        'Sleep science research suggests strategies to improve sleep quality. What is your primary goal for better sleep?',
      choices: randomize([
        'Fall asleep faster',
        'Stay asleep throughout the night',
        'Improve sleep quality',
        'Wake up refreshed',
        'Reduce sleep debt',
      ]),
      delay: 'random',
    }),
    radioPage({
      name: 'customized_approach',
      title: '{combined_customized_phrase} need a customized approach to {goal_phrase}. Pick which best describes you.',
      choices: randomize([
        'I struggle to maintain a healthy sleep schedule',
        'I have some good sleep habits',
        'My sleep routine is strong & my schedule is consistent',
      ]),
      delay: 'random',
    }),
    interstitial({
      name: 'success_stories_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_thumbs_up} style={{maxHeight: '200px'}} />}
          bodyText={`<div class="bold text-x-large leading-tight">Success<br />Stories</div><div class="text-large leading-loose">Join over 190,000 people ${surveyInstance.getVariable('goal_success_phrase')} with RISE</div>`}
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      name: 'wake_up',
      title:
        'Learn insights from sleep specialists on ways to wake up refreshed. How do you feel when you wake up in the morning?',
      choices: randomize([
        'I jump out of bed ready to conquer the day',
        'I feel groggy and sluggish',
        'I hit snooze multiple times',
        'I need a crane to lift me out of bed',
        'I wake up feeling refreshed',
      ]),
      delay: 'random',
    }),
    interstitial({
      name: 'sleep_debt_introduction_one_night_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_graph_single} style={{maxHeight: '233px'}} />}
          headerText={`<div class="left">Most ${surveyInstance.getVariable('gendered_noun_plural')} ${surveyInstance.getVariable(
            'in_their_age_group',
          )} get <span style="color:#B85C00;">an hour less</span> sleep each night than their bodies truly need</div>`}
          continueButtonTitle="Next"
        />
      ),
    }),
    interstitial({
      name: 'sleep_debt_introduction_one_week_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_graph_multiple} style={{maxHeight: '233px'}} />}
          headerText={`<div class="left">That’s <span style="color:#B85C00;">7 hours</span> of Sleep Debt every week, nearly a full night lost</div>`}
          bodyText={`<div class="heading3 bold left">What is Sleep Debt?</div><div class="left color-gray3">Sleep Debt is the gap between the sleep your body needs and the sleep it gets</div>`}
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      name: 'sleep_patterns',
      title: 'When did you typically wake up in the last 2 weeks?',
      choices: ['Before 6 AM', '6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
      delay: 'random',
    }),
    radioPage({
      name: 'bedtime_routines',
      title: 'When did you typically go to bed in the last 2 weeks?',
      choices: ['Before 10 PM', '10-11 PM', '11 PM-12 AM', '12-1 AM', 'After 1 AM', 'Varied'],
      delay: 'random',
    }),
    radioPage({
      name: 'sleep_consistency',
      title:
        'Guidance based on studies about sleep consistency. If you had complete freedom, when would you ideally wake up every day?',
      choices: ['6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
      delay: 'random',
    }),
    radioPage({
      name: 'weekend_wake_up',
      title:
        'Research into sleep routines highlights weekend impacts. Do you usually keep the same wake-up time on weekends?',
      choices: randomize([
        'Yes, I stick to my schedule',
        'I sleep in a bit',
        'I wake up earlier',
        'My weekend wake-up varies',
      ]),
      delay: 'random',
    }),
    interstitial({
      name: 'sleep_debt_accumulation_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_sleep_debt_accumulation} style={{maxHeight: '200px'}} />}
          headerText={`<div class="left">Most people lose sleep during the week and try to catch up on weekends, but fall short</div>`}
          bodyText={`<div class="left heading3 color-gray2">Sleep Debt accumulates over time</div>`}
          continueButtonTitle="Next"
        />
      ),
    }),
    interstitial({
      name: 'sleep_debt_category_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={
            <img
              src={getSleepDebtChartImage(
                getSleepDebtZoneFromMidpointOffsets(
                  surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
                  surveyInstance.getVariable('wake_midpoint_midnight_offset'),
                ),
              )}
              style={{maxHeight: '226px'}}
            />
          }
          headerText={`<div class="left">Based on what you told us, your estimated Sleep Debt is <span style="color:${getSleepDebtColor(
            getSleepDebtZoneFromMidpointOffsets(
              surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
              surveyInstance.getVariable('wake_midpoint_midnight_offset'),
            ),
          )}">${getSleepDebtZoneFromMidpointOffsets(
            surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
            surveyInstance.getVariable('wake_midpoint_midnight_offset'),
          )}</span></div>`}
          continueButtonTitle="Next"
          onClick={() => {
            surveyInstance.getQuestionsByNames(['sleep_debt_category_interstitial']).forEach(q => {
              q.value = getSleepDebtZoneFromMidpointOffsets(
                surveyInstance.getVariable('bedtime_midpoint_midnight_offset'),
                surveyInstance.getVariable('wake_midpoint_midnight_offset'),
              );
            });
          }}
        />
      ),
    }),
    interstitial({
      name: '4_out_of_5_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_rise_twinkles} style={{maxHeight: '200px'}} />}
          bodyText={
            '<div class="bold text-x-large leading-tight">4 out of 5</div><div class="text-large leading-loose">users say RISE has helped them manage their sleep and energy.</div>'
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      name: 'environment',
      // Note: the {combined_customized_phrase} ends with "you", we are adding the "r" to turn it into "your"
      title:
        '{combined_customized_phrase}r environment can have a huge impact on your sleep. Which best describes the area you live in?',
      choices: randomize(['Rural', 'Suburban', 'A major city']),
      delay: 'random',
    }),
    radioPage({
      name: 'bedroom_temperature',
      title:
        'Insights from sleep specialists on sleep environment. How would you describe the temperature of your bedroom while sleeping?',
      choices: randomize(
        ['Cool and comfortable', 'Warm and cozy', 'Too hot or too cold'],
        ['Varies throughout the night'],
      ),
      delay: 'random',
    }),
    radioPage({
      name: 'noise_level',
      title:
        'Discover research into sleep environments and noise levels. How would you describe the noise level in your bedroom while sleeping?',
      choices: ['Silent', 'Some background noise', 'Noisy', 'Varies'],
      delay: 'random',
    }),
    radioPage({
      name: 'light_level',
      title:
        'Receive guidance based on studies about sleep environment. How would you describe the amount of light in your bedroom while sleeping?',
      choices: ['Pitch black', 'Dim light', 'Some light', 'Bright light'],
      delay: 'random',
    }),

    interstitial({
      name: 'sleep_debt_reduction_interstitial', ///(Note: Replaced sleep_debt_interstitial )
      component: () => (
        <RiseInterstitialTemplate
          heroImage={
            <div>
              <img src={img_sleep_debt_reduction_chart} style={{maxHeight: '211px'}} />
              <div
                className="color-gray3"
                style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}
              >
                <div style={{textAlign: 'left', marginLeft: '22px'}}>{format(new Date(), 'MMM d')}</div>
                <div style={{textAlign: 'right', marginRight: '18px'}}>{format(addWeeks(new Date(), 3), 'MMM d')}</div>
              </div>
            </div>
          }
          headerText={`<div class="left">Reduce your Sleep Debt in 3 weeks</div>`}
          bodyText={`<div class="left heading3 color-gray1">RISE helps you get consistent sleep without rebounding</div>`}
          continueButtonTitle="Next"
        />
      ),
    }),

    radioPage({
      name: 'dinner_habits',
      title: 'Find insights from sleep specialists on dinner habits. What does your typical dinner consist of?',
      choices: randomize(
        ['Balanced meal with protein and veggies', 'Fast food or takeout', 'Light snack', 'Skipping dinner'],
        ['Varies'],
      ),
      delay: 'random',
    }),
    radioPage({
      name: 'dinner_timing',
      // title: 'Research into dinner timing and sleep. When do you usually have dinner?',\
      // Note: the {combined_customized_phrase} ends with "you", we are adding the "r" to turn it into "your"
      title: '{combined_customized_phrase}r meal timing impacts your sleep. When do you typically eat dinner?',
      choices: ['Around 6 PM', 'Between 6-7 PM', 'Between 7-8 PM', 'After 8 PM', 'Varies'],
      delay: 'random',
    }),
    radioPage({
      name: 'hydration',
      title: 'Insights from sleep specialists on hydration. How much water do you typically drink in a day?',
      choices: ['Less than 1 liter', '1-2 liters', '2-3 liters', 'More than 3 liters', 'Varies'],
      delay: 'random',
    }),
    radioPage({
      name: 'caffeine_intake',
      title:
        'Guidance based on studies about caffeine and sleep. How often do you consume coffee or caffeinated drinks in a day?',
      choices: ['None', '1 cup', '2 cups', '3 cups', 'More than 3 cups'],
      delay: 'random',
    }),
    interstitial({
      name: 'nights_tracked_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_sleep_twinkles} style={{maxHeight: '200px'}} />}
          headerText={`You're in good hands!`}
          bodyText={
            '<div class="text-large leading-loose" style="padding-left:22px; padding-right:22px;"><div class="bold text-x-large leading-tight">1.7 Billion</div> nights of sleep tracked with RISE</div>'
          }
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      name: 'job_type',
      title: 'Insights from sleep specialists on daily routines. What is your job type?',
      choices: randomize(['Office job', 'Physical labor', 'Remote work', 'Shift work']),
      delay: 'random',
    }),
    radioPage({
      name: 'sports_activity',
      title: 'Research into shift work and physical activity. Do you participate in any sports activities?',
      choices: ['Yes, regularly', 'Occasionally', 'No, not currently', 'Varies'],
      delay: 'random',
    }),
    radioPage({
      name: 'screen_time',
      title:
        'Guidance based on studies about screen time. How many hours do you spend on screens (phone, computer, TV) per day?',
      choices: ['Less than 2 hours', '2-4 hours', '4-6 hours', 'More than 6 hours', 'Varies'],
      delay: 'random',
    }),

    radioPage({
      name: 'morning_energy',
      title: 'Insights from sleep specialists on energy levels. How do you feel within the first hour of waking up?',
      choices: randomize([
        'Energetic and alert',
        'Slightly groggy',
        'Need time to wake up',
        'Still half asleep',
        'Ready for a nap',
      ]),
      delay: 'random',
    }),
    interstitial({
      name: 'progress_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<ProgressChart />}
          headerText={`<div class="left">Enhance your sleep quality and daily energy</div>`}
          bodyText={`<div class="left heading3 color-gray2">See how personalized adjustments have accelerated improvements in energy levels for Rise users.</div>`}
          continueButtonTitle="Next"
        />
      ),
    }),
    //This will show `Male` gender
    interstitial({
      visibleIf: "{gender} == 'Male'",
      name: 'testimonial_male_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_male_hero} style={{maxHeight: '200px'}} />}
          headerText="Prioritizing sleep can be hard. RISE makes it easy."
          bodyText="<div class='text-large italic'>“In only a few weeks, I found myself more focused, energetic, and productive at work.” <div class='text-medium' style='padding-top:8px;'>-Chase</div></div>"
          continueButtonTitle="Next"
        />
      ),
    }),
    //This will show for any gender not `Male`
    interstitial({
      visibleIf: "{gender} != 'Male'",
      name: 'testimonial_female_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_female_hero} style={{maxHeight: '200px'}} />}
          headerText="RISE makes it easy to turn your sleep data into useful insights."
          bodyText="<div class='text-large italic'>“I’m kinder to myself when I feel down or less energized. I know how to get my energy back when I need it.” <div class='text-medium' style='padding-top:8px;'>-Morgane</div></div>"
          continueButtonTitle="Next"
        />
      ),
    }),
    radioPage({
      name: 'post_lunch_energy',
      title: 'Research into post-lunch energy levels. How do you feel after lunch?',
      choices: randomize(
        ['Energized and focused', 'Slightly sluggish', 'In need of a caffeine boost', 'Ready for a nap'],
        ['Varies'],
      ),
      delay: 'random',
    }),
    radioPage({
      name: 'evening_energy',
      title:
        'Guidance based on studies about evening energy levels. When do you start feeling less energetic in the evening?',
      choices: ['Early evening', 'Late evening', 'After dinner', 'Before bedtime', 'Varies'],
      delay: 'random',
    }),

    interstitial({
      name: 'boosted_energy_interstitial',
      component: BoostedEnergyInterstitial,
    }),

    radioPage({
      name: 'calendar_flexibility',
      title: 'How flexible are you in adjusting your calendar planning based on your energy peaks?',
      choices: ['Very flexible', 'Somewhat flexible', 'Not very flexible', 'Not flexible at all'],
      delay: 'random',
    }),
    radioPage({
      name: 'bedtime_obstacles',
      title: 'Are there any obstacles that could affect your bedtime routine?',
      choices: randomize(
        ['Noise disturbances', 'Screen time', 'Stress or anxiety', 'Room temperature'],
        ['No obstacles'],
      ),
      delay: 'random',
    }),
    interstitial({name: 'analyze_interstitial', component: AnalyzeInterstitial}),
    interstitial({name: 'sports_interstitial', component: SportsInterstitial}),
    interstitial({
      name: 'app_credibility_interstitial',
      component: () => (
        <RiseInterstitialTemplate
          heroImage={<img src={img_editors_choice} style={{maxHeight: '220px'}} />}
          bodyText='<div class="text-x-large"><div class="bold">7.94 million</div>downloads</div>'
          continueButtonTitle="Next"
        />
      ),
    }),
  ],
};

/**
 * @note For dev use only
 * Do not use this in production code. It is untested and loosy goosey with the types.
 *
 * @description It grabs page definitions from the survey
 *  config so we can easily add them to a QA or Dev variant
 */
const pickSurveyItem_UNSAFE = (name: string) => {
  return survey_config_web_testimonial_credibility_oct_2024.pages.find(page => {
    // This ANY is gross, but a necessary evil for now.
    return (page.elements as any).find((element: any) => element.name === name);
  }) as RiseSurveyPage | RiseInterstitialPage;
};

// FOR DEV ONLY
export const qa_dynamic_questions_interstitials = {
  ...survey_config_base,
  title: 'QA Dynamic Questions',
  calculatedValues,
  pages: [
    pickSurveyItem_UNSAFE('age'),
    pickSurveyItem_UNSAFE('gender'),
    pickSurveyItem_UNSAFE('goal'),
    pickSurveyItem_UNSAFE('customized_approach'),
    pickSurveyItem_UNSAFE('sleep_patterns'),
    pickSurveyItem_UNSAFE('bedtime_routines'),
    pickSurveyItem_UNSAFE('environment'),
    // pickSurveyItem_UNSAFE('success_stories_interstitial'),
    // pickSurveyItem_UNSAFE('sleep_debt_introduction_one_night_interstitial'),
    // pickSurveyItem_UNSAFE('sleep_debt_introduction_one_week_interstitial'),
    // pickSurveyItem_UNSAFE('sleep_debt_accumulation_interstitial'),
    // pickSurveyItem_UNSAFE('sleep_debt_category_interstitial'),
    // pickSurveyItem_UNSAFE('4_out_of_5_interstitial'),
    // pickSurveyItem_UNSAFE('sleep_debt_reduction_interstitial'),
    // pickSurveyItem_UNSAFE('nights_tracked_interstitial'),
    // pickSurveyItem_UNSAFE('progress_interstitial'),
    // pickSurveyItem_UNSAFE('testimonial_male_interstitial'),
    // pickSurveyItem_UNSAFE('testimonial_female_interstitial'),
    // pickSurveyItem_UNSAFE('boosted_energy_interstitial'),
  ],
};
export const qa_sleep_debt_category = {
  ...survey_config_base,
  title: 'QA Sleep Debt Survey',
  calculatedValues,
  pages: [
    pickSurveyItem_UNSAFE('sleep_patterns'),
    pickSurveyItem_UNSAFE('bedtime_routines'),
    pickSurveyItem_UNSAFE('sleep_debt_category_interstitial'),
  ],
};

import styles from './LoadingSpinner.module.css';
import loading_spinner from './loading_spinner.svg';

export const LoadingSpinner = () => (
  <div className={styles.loading}>
    <div>
      <img className={styles.checkoutSpinner} src={loading_spinner} alt="" />
      <span className={styles.screenReaderOnly}>Loading...</span>
    </div>
  </div>
);

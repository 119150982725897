import React, {useState, useEffect} from 'react';
import riseLogo from 'images/rise-logo-with-twinkles.png';
import './LoadingScreen.css';

const LoadingScreen = ({delay = 1000}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const logoTimer = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => {
      clearTimeout(logoTimer);
    };
  }, [delay]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="loading-screen-container">
      <img src={riseLogo} alt="Rise Logo" className="loading-screen-logo" />
    </div>
  );
};

export default LoadingScreen;

import styles from './CheckoutButtons.module.css';

export const BlackButton = ({title, onClick}: {title: string; onClick?: () => void}) => (
  <button className={[styles.blackButton, styles.button].join(' ')} onClick={() => (onClick ? onClick() : null)}>
    {title}
  </button>
);

export const DiscountButton = ({title, onClick}: {title: string; onClick?: () => void}) => (
  <button className={[styles.discountButton, styles.button].join(' ')} onClick={() => (onClick ? onClick() : null)}>
    {title}
  </button>
);

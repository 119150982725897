import stars from './assets/5star.svg';
import user1 from './assets/user1.png';
import user2 from './assets/user2.png';
import user3 from './assets/user3.png';
import styles from './Testimonials.module.css';
import common from 'common.module.css';
export const Testimonials = () => (
  <section className={styles.container}>
    <div className={styles.testimonial}>
      <div className={styles.attribution}>
        <img src={user1} alt="Morgane B." className={styles.userImage} />
        <div className={styles.starsContainer}>
          <img src={stars} alt="5 stars" />
          <div className={[styles.userName, common.heading6].join(' ')}>Morgane B.</div>
        </div>
      </div>
      <div className={styles.quote}>
        “I was initially skeptical, but RISE transformed my sleep habits. I’ve adjusted by bedtime and wake time
        slightly based on the app’s circadian rhythm guidance, and even my weekends have improved.”
      </div>
    </div>

    <div className={styles.testimonial}>
      <div className={styles.attribution}>
        <img src={user2} alt="Chris R." className={styles.userImage} />
        <div className={styles.starsContainer}>
          <img src={stars} alt="5 stars" />
          <div className={[styles.userName, common.heading6].join(' ')}>Chris R.</div>
        </div>
      </div>
      <div className={styles.quote}>
        “I’ve always had a hard time falling asleep, but RISE’s recommendations have made a significant difference.
        Tracking my sleep debt has been a game changer and I appreciate the tailored advice.”
      </div>
    </div>

    <div className={styles.testimonial}>
      <div className={styles.attribution}>
        <img src={user3} alt="Anna K." className={styles.userImage} />
        <div className={styles.starsContainer}>
          <img src={stars} alt="5 stars" />
          <div className={[styles.userName, common.heading6].join(' ')}>Anna K.</div>
        </div>
      </div>
      <div className={styles.quote}>
        “I used to struggle with falling asleep and felt groggy all morning. RISE has been like having a sleep coach in
        my pocket. The app’s personalized tips have completely changed my mornings.”
      </div>
    </div>
  </section>
);

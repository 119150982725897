import {shuffle} from 'lodash';

/**
 * Randomizes the choices array, and pins the pinned choices at the beginning of the array.
 * @param choices - The array of choices to randomize.
 * @param pinned - The array of choices to pin at the beginning of the randomized array.
 * @returns The randomized array with pinned choices at the beginning.
 */

export const randomize = (choices: string[], pinnedBottom?: string[]) => {
  const shuffled = shuffle(choices);
  return pinnedBottom ? [...shuffled, ...pinnedBottom] : shuffled;
};

import Environment from './Environment';

export type PlanInfo = {
  price: number;
  introPrice: number;
  perDay: number;
  percentOff: number | null;
  label: string;
  id: string;
  purchasePrice: number;
  duration: string;
  frequency: string;
  introDuration: string;
  currency: SupportedCurrency;
  product: StripeProducts;
  payPalPlanId: string;
};

export type SupporedCountryCode = 'US' | 'CA' | 'GB' | 'AU';

type SupportedCurrency = 'usd' | 'cad' | 'gbp' | 'aud';

type StripeProducts = 'web_acquisition_week' | 'web_acquisition_month' | 'web_acquisition_quarter';

const inProduction = Environment.isProduction;

export const weekPlan: Record<SupporedCountryCode, PlanInfo> = {
  AU: {
    price: 44.99,
    introPrice: 10.99,
    perDay: 1.57,
    percentOff: 77,
    label: 'A$',
    id: inProduction ? 'price_1Q2HZwH7jib4x1GyLOH4D7yM' : 'price_1PzjNMH7jib4x1Gym8xcx361',
    purchasePrice: 1099,
    frequency: 'Weekly',
    duration: 'month',
    introDuration: '1 week',
    currency: 'aud',
    product: 'web_acquisition_week',
    payPalPlanId: 'P-785740446W877063FM35NNXQ',
    // runwayer payPalPlanId: 'P-3WN75667YX064833FMZPAOII',
  },
  GB: {
    price: 23.99,
    introPrice: 5.99,
    perDay: 0.86,
    percentOff: 77,
    label: '£',
    id: inProduction ? 'price_1Q2HZvH7jib4x1GyTH0P7FB9' : 'price_1PzjNLH7jib4x1GykjMdt6Bb',
    purchasePrice: 599,
    frequency: 'Weekly',
    duration: 'month',
    introDuration: '1 week',
    currency: 'gbp',
    product: 'web_acquisition_week',
    payPalPlanId: 'P-25B97445UX507703EM35NPAA',
    // runwayer payPalPlanId: 'P-8UE83171465288448MZPAMIY',
  },
  CA: {
    price: 39.99,
    introPrice: 9.99,
    perDay: 1.43,
    percentOff: 77,
    label: 'C$',
    id: inProduction ? 'price_1Q2HZvH7jib4x1Gya6rr4kx0' : 'price_1PzjNLH7jib4x1GyuNRwsMHM',
    purchasePrice: 999,
    frequency: 'Weekly',
    duration: 'month',
    introDuration: '1 week',
    currency: 'cad',
    product: 'web_acquisition_week',
    payPalPlanId: 'P-75032166AH942844HM35NQBA',
    // runwayer payPalPlanId: 'P-6GH39186YH2710410MZPAPQI',
  },
  US: {
    price: 29.99,
    introPrice: 6.99,
    perDay: 1,
    percentOff: 77,
    label: '$',
    id: inProduction ? 'price_1Q2HZvH7jib4x1GyqNyvtdU3' : 'price_1PzjNLH7jib4x1Gyox9lzNKi',
    purchasePrice: 699,
    frequency: 'Weekly', // TODO: if we get to translations - make this a lookup key vs the string (and lookup in component)
    duration: 'month',
    introDuration: '1 week',
    currency: 'usd',
    product: 'web_acquisition_week',
    payPalPlanId: 'P-80V72673CT033562DM35NRFQ',
    // runwayer payPalPlanId: 'P-98G47623Y89150800MZPAIDI',
  },
};

export const monthPlan: Record<SupporedCountryCode, PlanInfo> = {
  AU: {
    price: 44.99,
    introPrice: 29.99,
    perDay: 1,
    percentOff: 33,
    label: 'A$',
    id: inProduction ? 'price_1Q2HaGH7jib4x1GyF610xfeZ' : 'price_1PzjNsH7jib4x1GysmsZ74gy',
    purchasePrice: 2999,
    frequency: 'Monthly',
    duration: 'month',
    introDuration: '1 month',
    currency: 'aud',
    product: 'web_acquisition_month',
    payPalPlanId: 'P-4UK69520HE803161MM35NSOA',
    // runwayer payPalPlanId: 'P-24902817V0307741XMZPAOYY',
  },
  GB: {
    price: 23.99,
    introPrice: 15.99,
    perDay: 0.53,
    percentOff: 33,
    label: '£',
    id: inProduction ? 'price_1Q2HaGH7jib4x1GysOttxQdP' : 'price_1PzjNsH7jib4x1GyeAJ63EWj',
    purchasePrice: 1599,
    frequency: 'Monthly',
    duration: 'month',
    introDuration: '1 month',
    currency: 'gbp',
    product: 'web_acquisition_month',
    payPalPlanId: 'P-0A528514040276333M35NTCQ',
    // runwayer payPalPlanId: 'P-8HT630535G2283148MZPAM5Q',
  },
  CA: {
    price: 39.99,
    introPrice: 26.99,
    perDay: 0.9,
    percentOff: 33,
    label: 'C$',
    id: inProduction ? 'price_1Q2HaGH7jib4x1Gyct7Wxlvf' : 'price_1PzjNrH7jib4x1GyoPeTs7mY',
    purchasePrice: 2699,
    frequency: 'Monthly',
    duration: 'month',
    introDuration: '1 month',
    currency: 'cad',
    product: 'web_acquisition_month',
    payPalPlanId: 'P-6U793835NC6199612M35QGFY',
    // runwayer payPalPlanId: 'P-5PS70357XE605544DMZPAP2A',
  },
  US: {
    price: 29.99,
    introPrice: 19.99,
    perDay: 0.67,
    percentOff: 33,
    label: '$',
    id: inProduction ? 'price_1Q2HaFH7jib4x1GyDBxe1amV' : 'price_1PzjNrH7jib4x1GyGVxXczHC',
    purchasePrice: 1999,
    frequency: 'Monthly',
    duration: 'month',
    introDuration: '1 month',
    currency: 'usd',
    product: 'web_acquisition_month',
    payPalPlanId: 'P-9BE96908P23979335M35NUIA',
    // runwayer payPalPlanId: 'P-4M1563150S375270KMZPAJDQ',
  },
};

export const quarterPlan: Record<SupporedCountryCode, PlanInfo> = {
  AU: {
    price: 69.99,
    introPrice: 44.99,
    perDay: 0.49,
    percentOff: 40,
    label: 'A$',
    id: inProduction ? 'price_1Q2HaNH7jib4x1GyP2xaAlKc' : 'price_1PzjOSH7jib4x1GyFgmpp9BO',
    purchasePrice: 4499,
    frequency: 'Quarterly',
    duration: 'quarter',
    introDuration: 'a quarter',
    currency: 'aud',
    product: 'web_acquisition_quarter',
    payPalPlanId: 'P-1EJ1620345645241EM35N2OQ',
    // runwayer payPalPlanId: 'P-3SR96799GC793003XMZPAPFY',
  },
  GB: {
    price: 39.99,
    introPrice: 23.99,
    perDay: 0.26,
    percentOff: 40,
    label: '£',
    id: inProduction ? 'price_1Q2HaMH7jib4x1GydRZ2Er9s' : 'price_1PzjORH7jib4x1GyzoaPNkmM',
    purchasePrice: 2399,
    frequency: 'Quarterly',
    duration: 'quarter',
    introDuration: 'a quarter',
    currency: 'gbp',
    product: 'web_acquisition_quarter',
    payPalPlanId: 'P-75967049867063901M35N3BI',
    // runwayer payPalPlanId: 'P-09P17091YA162804SMZPANJY',
  },
  CA: {
    price: 69.99,
    introPrice: 39.99,
    perDay: 0.44,
    percentOff: 40,
    label: 'C$',
    id: inProduction ? 'price_1Q2HaMH7jib4x1GynlPkY46b' : 'price_1PzjORH7jib4x1GyWzz3ypKW',
    purchasePrice: 3999,
    frequency: 'Quarterly',
    duration: 'quarter',
    introDuration: 'a quarter',
    currency: 'cad',
    product: 'web_acquisition_quarter',
    payPalPlanId: 'P-2DV00477H6861933HM35N3SY',
    // runwayer payPalPlanId: 'P-24444615T1380040UMZPAQEI',
  },
  US: {
    price: 49.99,
    introPrice: 29.99,
    perDay: 0.33,
    percentOff: 40,
    label: '$',
    id: inProduction ? 'price_1Q2HaMH7jib4x1GymUS9QTvE' : 'price_1PzjORH7jib4x1GyPrqSfXIH',
    purchasePrice: 2999,
    frequency: 'Quarterly',
    duration: 'quarter',
    introDuration: 'a quarter',
    currency: 'usd',
    product: 'web_acquisition_quarter',
    payPalPlanId: 'P-1L66894790428054SM35N4HA',
    // runwayer payPalPlanId: 'P-9SM47879VF842110XMZPAJSA',
  },
};

import {useRef} from 'react';

/**
 * WIll fire one time when the component initially mounts.
 * If `shouldFire` is supplied, it needs to be `true` or
 * return `true` before it will try to fire. After it
 * returns `true` and the `effect()` fires, it will not try
 * to fire again.
 *
 * @param effect The function to fire once
 * @param shouldFire Optional, `boolean` or function that returns a `boolean` must be true to fire
 */
export function useEffectOnce(effect: () => void, shouldFire: boolean | (() => boolean) = true) {
  const hasFired = useRef(false);
  const fireAway = typeof shouldFire === 'function' ? shouldFire() : shouldFire;
  if (!hasFired.current && fireAway) {
    effect();
    hasFired.current = true;
  }
}

import {FeatureDefinitions, GrowthBook} from '@growthbook/growthbook-react';
import growthBookPayload from 'growthbook-payload.json';
import Analytics from 'utils/Analytics';
import Identity from 'utils/Identity';
import Environment from './Environment';
import {devLog} from 'utils/devLog';

export const setupGrowthbook = () => {
  const t1 = performance.now();
  const anonId = Identity.ensureAnonymousID();
  growthBook.setAttributes({web_funnel_anon_id: anonId});
  devLog('GrowthBook set attr: web_funnel_anon_id', growthBook.getAttributes().web_funnel_anon_id);

  growthBook
    .init({
      // Load features form the local cache when the app renders
      payload: {features: growthBookPayload.features as FeatureDefinitions},
    })
    .then(() => {
      console.log('GrowthBook initialized. Took: ', performance.now() - t1);
      devLog('GrowthBook Payload: ', growthBook.getPayload());
    })
    .catch(error => {
      devLog('GrowthBook Problem setting payload: ', error);
    });

  Analytics.onReady(() => {
    Analytics.segment.user().then(user => {
      growthBook.updateAttributes({id: user.anonymousId()});
      devLog('GrowthBook set attr: id', growthBook.getAttributes().id);
    });
  });
};

export const growthBook = new GrowthBook({
  // NOTE: Growthbook is being manually initialized in setupGrowthbook
  enableDevMode: !Environment.isProduction,
  trackingCallback: (experiment, result) => {
    devLog('Growthbook trackingCallback', experiment, result);
    Analytics.track('temp_growthbook_experiment', {
      experimentId: experiment.key ?? 'N/A',
      experimentValue: result.value ?? 'N/A',
      variationId: result.key ?? 'N/A',
      variantName: result.name ?? 'N/A',
      inExperiment: result?.inExperiment ?? 'N/A',
      resultValue: result?.value ?? 'N/A',
      gbEnvironment: Environment.isProduction ? 'production' : 'development',
      growthBookReady: growthBook.ready ?? 'N/A',
      bucket: result?.bucket ?? 'N/A',
      hashUsed: result?.hashUsed ?? 'N/A',
      hashValue: result?.hashValue ?? 'N/A',
      surface: 'web-funnel',
    });
  },
  onFeatureUsage(featureKey, result) {
    devLog('Growthbook onFeatureUsage', featureKey, result);
    Analytics.track('temp_growthbook_feature_used', {
      experimentId: featureKey,
      source: result.source,
      variationId: result?.experimentResult?.key ?? 'N/A',
      variantName: result.experimentResult?.name ?? 'N/A',
      inExperiment: result.experimentResult?.inExperiment ?? 'N/A',
      experimentValue: result.experimentResult?.value ?? 'N/A',
      resultValue: result.value ?? 'N/A',
      gbEnvironment: Environment.isProduction ? 'production' : 'development',
      growthBookReady: growthBook.ready ?? 'N/A',
      surface: 'web-funnel',
    });
  },
});

import {Duration, intervalToDuration} from 'date-fns';
import {useEffect, useState} from 'react';
import styles from './OfferExpirationTimer.module.css';
import stopwatch from './assets/stopwatch.svg';

const formatDuration = (duration: Duration) => {
  const minutes = duration.minutes?.toString().padStart(2, '0') ?? '00';
  const seconds = duration.seconds?.toString().padStart(2, '0') ?? '00';
  return `${minutes}:${seconds}`;
};

export const useOfferExpirationTimer = () => {
  const timerDuration = 5 * 60 * 1000;
  const [timedOut, setTimedOut] = useState(false);
  const [timeLeft, setTimeLeft] = useState(timerDuration);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft <= 1000) {
          setTimedOut(true);
          clearInterval(interval);
          return 0;
        }
        return prevTimeLeft - 1000;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const duration = formatDuration(intervalToDuration({start: 0, end: timeLeft}));
  return {timedOut, duration};
};

export const OfferExpirationTimer = () => {
  const {timedOut, duration} = useOfferExpirationTimer();
  return (
    <div className={styles.timer}>
      <img src={stopwatch} alt="Stopwatch" className={styles.clockImg} />
      <div>{timedOut ? 'We saved your discount!' : `This offer expires in ${duration}`}</div>
    </div>
  );
};

import {useGrowthBook} from '@growthbook/growthbook-react';
import {isNil} from 'lodash';
import React from 'react';

type GrowthBookReadyArgs = {
  children: React.ReactNode;
  timeout?: number;
  fallback?: React.ReactNode;
  requiredGBAttributes: string[];
};

export function GrowthBookReady({children, timeout, fallback, requiredGBAttributes}: GrowthBookReadyArgs) {
  const gb = useGrowthBook();
  const [hitTimeout, setHitTimeout] = React.useState(false);
  const requiredGBAttributesSet = requiredGBAttributes.every(attr => !isNil(gb.getAttributes()[attr]));
  const ready = gb ? gb.ready && requiredGBAttributesSet : false;

  React.useEffect(() => {
    if (timeout && !ready) {
      const timer = setTimeout(() => {
        gb &&
          gb.log('FeaturesReady timed out waiting for features to load', {
            timeout,
          });
        setHitTimeout(true);
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, [timeout, ready, gb]);

  return <span>{ready || hitTimeout ? children : fallback}</span>;
}

import {useFeature, useFeatureValue} from '@growthbook/growthbook-react';
import {Navigate, Route, Routes} from 'react-router-dom';
import AccountReady from 'screens/AccountReady/AccountReady';
import RunwayerCheckoutScreen from 'screens/CheckoutScreen/CheckoutScreen';
import {RiseCheckoutScreenV1} from 'screens/RiseCheckoutV1/screens/RiseCheckoutV1Screen/RiseCheckoutScreenV1';
import RiseSurvey from 'screens/RiseSurvey/RiseSurvey';
import {survey_config_runwayer_sept_2024_rise_variant} from 'surveys/runwayer_sept_2024_rise_variant';
import {survey_config_web_testimonial_credibility_oct_2024} from 'surveys/testimonial_credibility_oct_2024';

const SurveyContainer = () => {
  const inExperiment = useFeature('web_testimonial_credibility');
  const randomSurveyConfig = inExperiment.value
    ? survey_config_web_testimonial_credibility_oct_2024
    : survey_config_runwayer_sept_2024_rise_variant;
  return <RiseSurvey surveyConfigJson={randomSurveyConfig} />;
};

const CheckoutScreenContainer = () => {
  const paywallExperimentEnabled = useFeatureValue('web_paywall_remove_weekly', false);
  if (paywallExperimentEnabled) {
    return <RiseCheckoutScreenV1 variantName="rise-checkout-v1" />;
  }
  return <RunwayerCheckoutScreen variantName="runwayer-checkout" />;
};

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/start" replace />} />
      <Route index path="start" element={<SurveyContainer />} />
      <Route path="checkout" element={<CheckoutScreenContainer />} />
      <Route path="account-ready" element={<AccountReady />} />
    </Routes>
  );
};

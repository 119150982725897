import React from 'react';
import {ContinueButton} from './ContinueButton';

export type RiseSurveyInterstitialControlsButton = {
  visible: boolean;
};

type RiseSurveyInterstitialControlsProps = {
  onClick?: () => void;
  continueButtonTitle?: string;
};

export const RiseSurveyInterstitialControls: React.FC<RiseSurveyInterstitialControlsProps> = ({
  continueButtonTitle,
  onClick,
}) => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '16px',
      }}
    >
      <ContinueButton title={continueButtonTitle} onClick={onClick} />
    </div>
  );
};

export default RiseSurveyInterstitialControls;

import feat_buzzfeed from './assets/1.webp';
import feat_forbes from './assets/4.webp';
import feat_goodhousekeeping from './assets/5.webp';
import feat_lifewire from './assets/6.webp';
import feat_techcrunch from './assets/7.webp';
import feat_nyt from './assets/8.webp';
import feat_today from './assets/9.webp';
import styles from './RiseFeatured.module.css';
import common from 'common.module.css';

export const RiseFeatured = () => {
  return (
    <section>
      <div className={common.heading1}>Featured in</div>
      <div className={styles.carouselContainer}>
        <div className={styles.carousel}>
          <div className={styles.carouselItem}>
            <img src={feat_nyt} alt="NYT" />
          </div>
          <div className={styles.carouselItem}>
            <img src={feat_buzzfeed} alt="Buzzfeed" />
          </div>
          <div className={styles.carouselItem}>
            <img src={feat_forbes} alt="Forbes" />
          </div>
          <div className={styles.carouselItem}>
            <img src={feat_goodhousekeeping} alt="Good Housekeeping" />
          </div>
          <div className={styles.carouselItem}>
            <img src={feat_lifewire} alt="Lifewire" />
          </div>
          <div className={styles.carouselItem}>
            <img src={feat_techcrunch} alt="TechCrunch" />
          </div>

          <div className={styles.carouselItem}>
            <img src={feat_today} alt="Today" />
          </div>
        </div>
      </div>
    </section>
  );
};

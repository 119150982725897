import React from 'react';
import './AnimatedBar.css';

type Props = {
  duration?: number;
  delay?: number;
};
const AnimatedBar: React.FC<Props> = ({duration = 1000, delay = 0}) => {
  return (
    <div className="bar-container">
      <div className="bar" style={{animationDuration: `${duration}ms`, animationDelay: `${delay}ms`}}></div>
    </div>
  );
};

export default AnimatedBar;

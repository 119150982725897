import AnalyzeInterstitial from 'screens/RiseSurvey/interstitials/AnalyzeInterstitial/AnalyzeInterstitial';
import BoostedEnergyInterstitial from 'screens/RiseSurvey/interstitials/BoostedEnergyInterstitial/BoostedEnergyInterstitial';
import ProgressInterstitial from 'screens/RiseSurvey/interstitials/ProgressInterstitial/ProgressInterstitial';
import SleepDebtInterstitial from 'screens/RiseSurvey/interstitials/SleepDebtInterstitial/SleepDebtInterstitial';
import SportsInterstitial from 'screens/RiseSurvey/interstitials/SportsInterstitial/SportsInterstitial';
import SuccessInterstitial from 'screens/RiseSurvey/interstitials/SuccessInterstitial/SuccessInterstitial';
import {survey_config_base} from 'screens/RiseSurvey/utils/__survey_config_base';
import {interstitial} from 'screens/RiseSurvey/utils/interstitial';
import {radioPage} from 'screens/RiseSurvey/utils/page';
import {randomize} from 'screens/RiseSurvey/utils/randomize';
import {RiseSurveyConfig} from 'types';

export const survey_config_runwayer_sept_2024_rise_variant: RiseSurveyConfig = {
  ...survey_config_base,
  title: 'V0 Rise Variant Sept 2024',
  pages: [
    radioPage({
      name: 'age',
      title: 'Sleep needs vary by age. How old are you?',
      choices: ['18-23', '24-29', '30-39', '40-49', '50-59', '60+'],
      delay: 'random',
    }),
    radioPage({
      name: 'current_quality',
      title:
        'Research shows that sleep quality impacts daily energy levels. How would you rate your current sleep quality?',
      choices: randomize([
        'I sleep like a baby',
        'I toss and turn a bit',
        'I struggle to fall asleep',
        'I wake up multiple times at night',
        'I feel exhausted in the morning',
      ]),
      delay: 'random',
    }),
    radioPage({
      name: 'goal',
      title:
        'Sleep science research suggests strategies to improve sleep quality. What is your primary goal for better sleep?',
      choices: randomize([
        'Fall asleep faster',
        'Stay asleep throughout the night',
        'Improve sleep quality',
        'Wake up refreshed',
        'Reduce sleep debt',
      ]),
      delay: 'random',
    }),
    radioPage({
      name: 'wake_up',
      title:
        'Learn insights from sleep specialists on ways to wake up refreshed. How do you feel when you wake up in the morning?',
      choices: randomize([
        'I jump out of bed ready to conquer the day',
        'I feel groggy and sluggish',
        'I hit snooze multiple times',
        'I need a crane to lift me out of bed',
        'I wake up feeling refreshed',
      ]),
      delay: 'random',
    }),
    radioPage({
      name: 'sleep_patterns',
      title:
        'Research about sleep patterns shows the importance of consistency. When did you typically wake up in the last 2 weeks?',
      choices: ['6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
      delay: 'random',
    }),
    interstitial({name: 'success_interstitial', component: SuccessInterstitial}),
    radioPage({
      name: 'bedtime_routines',
      title:
        'Obtain insights from sleep specialists on bedtime routines. When did you typically go to bed in the last 2 weeks?',
      choices: ['Before 10 PM', '10-11 PM', '11 PM-12 AM', 'After 12 AM', 'Varied'],
      delay: 'random',
    }),
    radioPage({
      name: 'sleep_consistency',
      title:
        'Guidance based on studies about sleep consistency. If you had complete freedom, when would you ideally wake up every day?',
      choices: ['6-7 AM', '7-8 AM', '8-9 AM', '9-10 AM', 'After 10 AM'],
      delay: 'random',
    }),
    radioPage({
      name: 'weekend_wake_up',
      title:
        'Research into sleep routines highlights weekend impacts. Do you usually keep the same wake-up time on weekends?',
      choices: randomize([
        'Yes, I stick to my schedule',
        'I sleep in a bit',
        'I wake up earlier',
        'My weekend wake-up varies',
      ]),
      delay: 'random',
    }),
    radioPage({
      name: 'bedroom_temperature',
      title:
        'Insights from sleep specialists on sleep environment. How would you describe the temperature of your bedroom while sleeping?',
      choices: randomize(
        ['Cool and comfortable', 'Warm and cozy', 'Too hot or too cold'],
        ['Varies throughout the night'],
      ),
      delay: 'random',
    }),
    radioPage({
      name: 'noise_level',
      title:
        'Discover research into sleep environments and noise levels. How would you describe the noise level in your bedroom while sleeping?',
      choices: ['Silent', 'Some background noise', 'Noisy', 'Varies'],
      delay: 'random',
    }),
    radioPage({
      name: 'light_level',
      title:
        'Receive guidance based on studies about sleep environment. How would you describe the amount of light in your bedroom while sleeping?',
      choices: ['Pitch black', 'Dim light', 'Some light', 'Bright light'],
      delay: 'random',
    }),
    interstitial({name: 'sleep_debt_interstitial', component: SleepDebtInterstitial}),
    radioPage({
      name: 'dinner_habits',
      title: 'Find insights from sleep specialists on dinner habits. What does your typical dinner consist of?',
      choices: randomize(
        ['Balanced meal with protein and veggies', 'Fast food or takeout', 'Light snack', 'Skipping dinner'],
        ['Varies'],
      ),
      delay: 'random',
    }),
    radioPage({
      name: 'dinner_timing',
      title: 'Your meal timing impacts your sleep. When do you typically eat dinner?',
      choices: ['Around 6 PM', 'Between 6-7 PM', 'Between 7-8 PM', 'After 8 PM', 'Varies'],
      delay: 'random',
    }),
    radioPage({
      name: 'hydration',
      title: 'Insights from sleep specialists on hydration. How much water do you typically drink in a day?',
      choices: ['Less than 1 liter', '1-2 liters', '2-3 liters', 'More than 3 liters', 'Varies'],
      delay: 'random',
    }),
    radioPage({
      name: 'caffeine_intake',
      title:
        'Guidance based on studies about caffeine and sleep. How often do you consume coffee or caffeinated drinks in a day?',
      choices: ['None', '1 cup', '2 cups', '3 cups', 'More than 3 cups'],
      delay: 'random',
    }),
    radioPage({
      name: 'job_type',
      title: 'Insights from sleep specialists on daily routines. What is your job type?',
      choices: randomize(['Office job', 'Physical labor', 'Remote work', 'Shift work']),
      delay: 'random',
    }),
    radioPage({
      name: 'sports_activity',
      title: 'Research into shift work and physical activity. Do you participate in any sports activities?',
      choices: ['Yes, regularly', 'Occasionally', 'No, not currently', 'Varies'],
      delay: 'random',
    }),
    radioPage({
      name: 'screen_time',
      title:
        'Guidance based on studies about screen time. How many hours do you spend on screens (phone, computer, TV) per day?',
      choices: ['Less than 2 hours', '2-4 hours', '4-6 hours', 'More than 6 hours', 'Varies'],
      delay: 'random',
    }),
    interstitial({name: 'progress_interstitial', component: ProgressInterstitial}),
    radioPage({
      name: 'morning_energy',
      title: 'Insights from sleep specialists on energy levels. How do you feel within the first hour of waking up?',
      choices: randomize([
        'Energetic and alert',
        'Slightly groggy',
        'Need time to wake up',
        'Still half asleep',
        'Ready for a nap',
      ]),
      delay: 'random',
    }),
    radioPage({
      name: 'post_lunch_energy',
      title: 'Research into post-lunch energy levels. How do you feel after lunch?',
      choices: randomize(
        ['Energized and focused', 'Slightly sluggish', 'In need of a caffeine boost', 'Ready for a nap'],
        ['Varies'],
      ),
      delay: 'random',
    }),
    radioPage({
      name: 'evening_energy',
      title:
        'Guidance based on studies about evening energy levels. When do you start feeling less energetic in the evening?',
      choices: ['Early evening', 'Late evening', 'After dinner', 'Before bedtime', 'Varies'],
      delay: 'random',
    }),
    interstitial({name: 'boosted_energy_interstitial', component: BoostedEnergyInterstitial}),
    radioPage({
      name: 'calendar_flexibility',
      title: 'How flexible are you in adjusting your calendar planning based on your energy peaks?',
      choices: ['Very flexible', 'Somewhat flexible', 'Not very flexible', 'Not flexible at all'],
      delay: 'random',
    }),
    radioPage({
      name: 'bedtime_obstacles',
      title: 'Are there any obstacles that could affect your bedtime routine?',
      choices: randomize(
        ['Noise disturbances', 'Screen time', 'Stress or anxiety', 'Room temperature'],
        ['No obstacles'],
      ),
      delay: 'random',
    }),
    interstitial({name: 'analyze_interstitial', component: AnalyzeInterstitial}),
    interstitial({name: 'sports_interstitial', component: SportsInterstitial}),
  ],
};

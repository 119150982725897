import React, {createElement} from 'react';
import {Question, ElementFactory, Serializer} from 'survey-core';
import {ReactQuestionFactory, SurveyQuestionElementBase} from 'survey-react-ui';

export type RiseInterstitialProps = {
  onValueSelect?: (value: any) => void;
};

export const QUESTION_RISE_INTERSTITIAL = 'rise_interstitial' as const;

class QuestionRiseInterstitialModel extends Question {
  getType() {
    return QUESTION_RISE_INTERSTITIAL;
  }
}

class SurveyQuestionRiseInterstitial extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {value: this.question.value};
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  handleValueSelect = (value: any) => {
    this.question.value = value;
  };

  renderElement() {
    //@ts-expect-error I need to access the jsonObj despite it being protected to get the component
    const CustomComponent = this.question.jsonObj.riseComponent as React.FC<RiseInterstitialProps>;
    return (
      <div data-testid="rise-interstitial">
        <CustomComponent onValueSelect={this.handleValueSelect} />
      </div>
    );
  }
}

//call this where the survey is created
export function registerRiseInterstitial() {
  ElementFactory.Instance.registerElement(QUESTION_RISE_INTERSTITIAL, name => {
    return new QuestionRiseInterstitialModel(name);
  });
  ReactQuestionFactory.Instance.registerQuestion(QUESTION_RISE_INTERSTITIAL, props => {
    return createElement(SurveyQuestionRiseInterstitial, props);
  });

  Serializer.addClass(
    QUESTION_RISE_INTERSTITIAL,
    [],
    function () {
      return new QuestionRiseInterstitialModel('');
    },
    'question',
  );
}

import {loadStripe, PaymentRequest, SetupIntentResult, Stripe, StripeElements} from '@stripe/stripe-js';
import {PlanInfo} from '../utils/stripePlans';
import {CardElement} from '@stripe/react-stripe-js';
import Environment from '../utils/Environment';
import * as Sentry from '@sentry/react';
import Analytics from '../utils/Analytics';

export const createStripeInstance = async (): Promise<Stripe | null | undefined> => {
  try {
    // if running locally, and you don't see this, run the link command
    const key = Environment.getVar('REACT_APP_STRIPE_KEY');
    if (!key) {
      throw new Error('Stripe key must be defined');
    }
    return await loadStripe(key);
  } catch (error: any) {
    Analytics.track('Stripe initialization error', {error});
    Sentry.captureException(new Error(error));
  }
};

export const createPaymentRequest = async (
  stripeInstance: Stripe,
  plan: PlanInfo,
): Promise<PaymentRequest | undefined> => {
  const paymentRequest = stripeInstance.paymentRequest({
    country: 'US', // country code of merchant
    currency: plan.currency,
    total: {label: plan.frequency, amount: plan.purchasePrice},
    requestPayerEmail: true,
    requestPayerName: true,
  });

  const canUseAppleOrGooglePay = await paymentRequest.canMakePayment();
  if (canUseAppleOrGooglePay) {
    const {applePay, googlePay} = canUseAppleOrGooglePay;
    Analytics.track('Platform Pay Options Shown', {applePay, googlePay, ...plan});
    return paymentRequest;
  } else {
    Analytics.track('Platform Pay Options Shown', {applePay: false, googlePay: false, ...plan});
    return undefined;
  }
};

export const validateCreditCard = async (
  clientSecret: string | undefined,
  stripe: Stripe | null,
  elements: StripeElements | null,
): Promise<{
  error: string | null;
  result: SetupIntentResult | null;
}> => {
  if (clientSecret === undefined || stripe === null || elements === null) {
    Sentry.captureMessage(
      `Card Validation Error: Client Secret ${clientSecret}; Stripe ${stripe}; Elements ${elements}`,
    );
    Analytics.track('Credit Card Validation Failure', {clientSecret, stripe, elements});
    return {error: 'Please refresh the page and try again', result: null}; // TODO: with i18n make this a key, not text
  }

  const card = elements.getElement(CardElement);
  if (card === null) {
    Sentry.captureMessage('Card Validation Error: Card is null');
    Analytics.track('Credit Card Validation Failure', {card: 'null', clientSecret, stripe, elements});
    return {error: 'Please enter your card details', result: null}; // TODO: with i18n make this a key, not text
  }

  const result = await stripe.confirmCardSetup(clientSecret, {
    payment_method: {
      card,
    },
  });

  if (result.error) {
    // error via Stripe
    // This point will be reached if there is an immediate error when
    // confirming the payment - for example, payment details incomplete or card declined
    Analytics.track('Credit Card Validation Failure', {fromStripe: true, error: result.error, card});

    return {
      result,
      error: result.error.message ? result.error.message : 'Could not complete checkout, please try again.',
    };
  }
  Analytics.track('Credit Card Validation Success');
  return {error: null, result};
};

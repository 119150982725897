import React, {useEffect} from 'react';
import './AccountReady.css';
import playButton from './assets/GooglePlay.png';
import appStoreButton from './assets/AppStore.png';
import {useLocation} from 'react-router-dom';
import checkmark from './assets/CircleCheckmark.svg';
import MobileDetect from 'mobile-detect';
import Analytics from 'utils/Analytics';

const AppStoreUrl = 'https://apps.apple.com/us/app/rise-sleep-tracker/id1453884781';
const PlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.risesci.nyx';

const whichStoreButton = (): 'ios' | 'android' | 'both' => {
  const md = new MobileDetect(window.navigator.userAgent);
  const isAndroid = md.os() === 'AndroidOS';
  const isIOS = md.os() === 'iOS';

  if (isIOS) {
    return 'ios';
  } else if (isAndroid) {
    return 'android';
  } else return 'both';
};

/**
 * Pass in email address with the query param `email`
 */

const AccountReady = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');

  const storeButton = whichStoreButton();

  useEffect(() => {
    Analytics.track('Store button shown', {storeButton});
  }, [storeButton]);

  return (
    <div className="account-ready" data-testid="screen-account-ready">
      <div className="checkmark-container">
        <img src={checkmark} alt="Checkmark" width={70} height={70} />
      </div>
      <div>
        <h2>
          Ready to Go!
          <br />
          <div style={{fontSize: '1.5rem'}}>Download the RISE App to Begin</div>
        </h2>
      </div>
      <div className="numbered-bullets">
        <div className="bullet-container">
          <div className="numbered-bullet">1</div>
          <div className="bullet-line" />
          <span>
            {storeButton === 'ios' ? (
              <a href={AppStoreUrl} style={{color: 'black'}}>
                Download
              </a>
            ) : storeButton === 'android' ? (
              <a href={PlayStoreUrl} style={{color: 'black'}}>
                Download
              </a>
            ) : (
              'Download'
            )}
            &nbsp;the app
          </span>
        </div>
        <div className="bullet-container">
          <div className="numbered-bullet">2</div>
          <div className="bullet-line" />
          <span>Login with {email?.length ? email : 'your email address'}</span>
        </div>
        <div className="bullet-container">
          <div className="numbered-bullet">3</div>
          <span>Enjoy your progress!</span>
        </div>
      </div>
      <div className="button-container">
        <div>Download the app now!</div>
        <div className="store-buttons">
          {storeButton === 'ios' || storeButton === 'both' ? (
            <a href={AppStoreUrl} className="store-button">
              <img src={appStoreButton} alt="Apple App Store" />
            </a>
          ) : null}
          {storeButton === 'android' || storeButton === 'both' ? (
            <a href={PlayStoreUrl} className="store-button">
              <img src={playButton} alt="Google Play Store" />
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AccountReady;

import community from './assets/community-map.webp';
import map from './assets/map.svg';
import styles from './CommunityInvitation.module.css';
import common from 'common.module.css';

export const CommunityInvitation = () => (
  <section className={styles.container}>
    <div className={common.heading1}>Join our community</div>
    <div className={[common.colorGray2, common.heading5].join(' ')}>Over 5 million happy users</div>
    <div className={styles.mapContainer}>
      <img src={map} alt="Community" className={styles.image} />
      <div className={styles.peoplesHeads}>
        <img src={community} alt="Community" className={styles.image} />
      </div>
    </div>
  </section>
);

import React from 'react';
import ButtonSolid from 'components/Buttons/ButtonSolid';
import Analytics from 'utils/Analytics';
import {surveyInstance} from '../../RiseSurvey';
import {SurveyData} from '../../utils/surveyData';

type ContinueButtonProps = {
  onClick?: () => void;
  title?: string;
};
export const ContinueButton: React.FC<ContinueButtonProps> = ({title, onClick}) => {
  return (
    <ButtonSolid
      title={title ?? 'Continue'}
      onClick={() => {
        onClick?.();
        const page = surveyInstance.currentPage;
        Analytics.track('Continue clicked', {
          survey_name: surveyInstance.title,
          question_title: page.questions[0].title,
          question_name: page.questions[0].name,
          question_value: page.questions[0]?.value,
        });
        if (surveyInstance) {
          SurveyData.save(surveyInstance);
        }
        if (surveyInstance?.isLastPage) {
          surveyInstance?.completeLastPage();
        } else {
          surveyInstance?.nextPage();
        }
      }}
    />
  );
};

type IfExpressionGenerator = (params: {if: string; then: string; else: string}) => `iif(${string},${string},${string})`;
/**
 * A convenience function that generates the `iif` expression for SurveyJS
 * The statements use the SurveyJS DSL (Domain Specific Language)
 *
 * @example
 * ifExpression({if: "{gender} == 'Male'", then: "Men", else: "Women"})
 *
 * @returns
 * "iif({gender} == 'Male', 'Men', 'Women')"
 */
export const ifExpression: IfExpressionGenerator = ({if: _if, then: _then, else: _else}) => {
  return `iif(${_if},${_then},${_else})`;
};

import {BlackButton} from '../CheckoutButtons/CheckoutButtons';
import {OfferExpirationTimer} from '../OfferExpirationTimer/OfferExpirationTimer';
import right_arrow from './assets/right_arrow.svg';
import styles from './PlanSelection.module.css';
import common from 'common.module.css';
import thumbs_up from './assets/thumbs_up.svg';
import {getUserCountry} from 'utils/locale';
import {monthPlan, PlanInfo, quarterPlan} from 'utils/stripePlans';

const PlanOption = ({
  index,
  selected,
  plan,
  onPriceSelection,
}: {
  index: number;
  selected: boolean;
  plan: PlanInfo;
  onPriceSelection: (plan: PlanInfo) => void;
}) => {
  return (
    <div
      className={`${styles.planSelection} ${selected ? styles.selected : ''}`}
      onClick={() => onPriceSelection(plan)}
    >
      <div className={`${styles.planSelectionHeader} ${selected && styles.selectedHeader}`}>
        {index === 1 ? 'LITE VERSION' : null}
        {index === 2 ? (
          <>
            <div className={styles.planSelectionHeaderLeft}>
              <span>MOST POPULAR</span>
              <img
                src={thumbs_up}
                alt="Thumbs Up"
                className={`${styles.thumbImg} ${selected && styles.thumbImageSelected}`}
              />
            </div>
            <div>
              <div className={styles.planSelectionHeaderRight}>-{`${plan.percentOff}`}%</div>
            </div>
          </>
        ) : null}
        {index === 3 ? (
          <>
            <div className={styles.planSelectionHeaderLeft}>
              <span>BEST PRICE</span>
            </div>
            <div>
              <div className={styles.planSelectionHeaderRight}>-{`${plan.percentOff}`}%</div>
            </div>
          </>
        ) : null}
      </div>
      <div className={`${styles.planSelectionPrice} ${selected && styles.selectedPrice}`}>
        <div className={styles.planSelectionHeaderPriceInfo}>
          <div className={styles.planSelectionDuration}>{plan.frequency}</div>
          <div className={styles.strikethroughContainer}>
            <s>{`${plan.label}${plan.price}`}</s>
            <img src={right_arrow} alt="Right Arrow" />
            <span>{`${plan.label}${plan.introPrice}`}</span>
          </div>
        </div>
        <div className={styles.planSelectionPerDay}>
          <div
            className={styles.planSelectionDollar}
          >{`${plan.label}${plan.perDay === 1 ? plan.perDay : plan.perDay.toFixed(2)}`}</div>
          <div className={styles.planSelectionDayInfo}>per day</div>
        </div>
      </div>
    </div>
  );
};

export const PlanSelection = ({
  selectedPlan,
  onPriceSelection,
  onButtonClick,
}: {
  selectedPlan: PlanInfo;
  onPriceSelection: (plan: PlanInfo) => void;
  onButtonClick: () => void;
}) => {
  const {countryCode: locale} = getUserCountry();
  const monthlyPlan = monthPlan[locale];
  const quarterlyPlan = quarterPlan[locale];

  return (
    <section className={styles.container}>
      <div className={[common.superBold, common.display1].join(' ')}>Choose your plan</div>
      <OfferExpirationTimer />
      <div className={styles.planSelectionContainer}>
        <PlanOption
          index={2}
          selected={selectedPlan.id === monthlyPlan.id}
          plan={monthlyPlan}
          onPriceSelection={onPriceSelection}
        />
        <PlanOption
          index={3}
          selected={selectedPlan.id === quarterlyPlan.id}
          plan={quarterlyPlan}
          onPriceSelection={onPriceSelection}
        />
        <div className={styles.planSelectionButton}>
          <BlackButton title="Get my plan" onClick={onButtonClick} />
        </div>
        <div className={[common.center, common.colorGray2, common.smallText].join(' ')}>
          Guaranteed safe checkout and
          <br />
          100% money-back guarantee
        </div>
      </div>
    </section>
  );
};

import React from 'react';
import ButtonClear from 'components/Buttons/ButtonClear';
import Analytics from 'utils/Analytics';
import {surveyInstance} from '../../RiseSurvey';
import {SurveyData} from '../../utils/surveyData';

export const SkipButton: React.FC = () => {
  return (
    <ButtonClear
      title={'Skip'}
      onClick={() => {
        const page = surveyInstance.currentPage;
        Analytics.track('Skip clicked', {
          survey_name: surveyInstance.title,
          question_title: page.questions[0].title,
          question_name: page.questions[0].name,
          pageNumber: surveyInstance.currentPageNo,
        });
        surveyInstance?.currentPage.questions.forEach((question: any) => {
          question.value = undefined;
        });
        if (surveyInstance?.isLastPage) {
          surveyInstance?.completeLastPage();
        } else {
          surveyInstance?.nextPage();
        }
        if (surveyInstance) {
          SurveyData.save(surveyInstance);
        }
      }}
    />
  );
};

import React from 'react';

const ProgressChart = () => {
  return (
    <div style={{position: 'relative'}}>
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '5px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'center',
          gap: '24px',
          paddingLeft: '24px',
          paddingRight: '24px',
        }}
      >
        {/* Left Bar */}
        <div
          style={{
            backgroundColor: 'rgb(238, 233, 221)',
            color: 'white',

            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            height: '50%',

            flex: 1,
            position: 'relative',
          }}
        >
          <div
            style={{
              padding: '10px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                bottom: '18px',
                left: '0',
                right: '0',
                fontSize: '12px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                fontWeight: 'bold',
                color: 'black',
              }}
            >
              ON YOUR OWN
            </div>
          </div>
        </div>
        {/* Right Bar */}
        <div
          style={{
            backgroundColor: 'black',
            color: 'white',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            height: '100%',
            flex: 1,
            position: 'relative',
          }}
        >
          <div style={{padding: '10px'}}>
            <div
              style={{
                position: 'absolute',
                top: '18px',
                left: '0',
                right: '0',
                fontSize: '32px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                fontWeight: 'bold',
                color: 'white',
              }}
            >
              2x 🎉
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: '18px',
                left: '0',
                right: '0',
                fontSize: '12px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                fontWeight: 'bold',
              }}
            >
              RISE
            </div>
          </div>
        </div>
      </div>
      <svg width="100%" height="auto" viewBox="0 0 311 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.1" width="311" height="80" fill="black"></rect>
        <path d="M0.5 40.5H310.5" stroke="#BFBEC2" strokeMiterlimit="16" strokeDasharray="1 2"></path>
        <path d="M0.5 80.5H310.5" stroke="#BFBEC2" strokeMiterlimit="16" strokeDasharray="1 2"></path>
        <path d="M0.5 120.5H310.5" stroke="#BFBEC2" strokeMiterlimit="16" strokeDasharray="1 2"></path>
        <path d="M0 159.5H311" stroke="black" strokeMiterlimit="16"></path>
      </svg>
    </div>
  );
};

export default ProgressChart;

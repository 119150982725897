import common from 'common.module.css';
import styles from './MoneyBackGuarantee.module.css';
import image from './assets/money-back-guarantee.png';
export const MoneyBackGuarantee = () => (
  <section className={styles.container}>
    <img src={image} alt="100% money-back guarantee" className={styles.image} />
    <div className={[styles.heading, common.heading1].join(' ')}>100% money-back guarantee</div>
    <div className={common.heading3}>
      We're confident RISE will improve your sleep. If we don't meet your expectations within 30 days, we'll refund your
      money.
    </div>
  </section>
);

import React from 'react';

const StaticSleepDebtChart: React.FC = () => {
  return (
    <div
      style={{
        height: '160px',
        alignSelf: 'stretch',
        boxSizing: 'border-box',
        position: 'relative',
        overflow: 'visible',

        margin: '12px',
      }}
    >
      <div
        style={{
          display: 'flex',
          padding: '40.5px 0.5px 0px',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          rowGap: '40px',
          columnGap: '40px',
          boxSizing: 'border-box',
          position: 'absolute',
          left: '0px',
          right: '0px',
          top: '-14px',
          height: '160px',
        }}
      >
        <div
          style={{
            height: '1px',
            strokeWidth: '1',
            stroke: 'red',
            boxSizing: 'border-box',
            position: 'absolute',
            left: '0.5px',
            right: '0.5px',
            top: '119px',
          }}
        >
          <svg
            preserveAspectRatio="none"
            width="311"
            height="1"
            viewBox="0 0 311 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{width: '100%', height: '100%'}}
          >
            <path d="M0.5 0.5H310.5" stroke="#C6C6C6" strokeMiterlimit="16" strokeDasharray="1 2"></path>
          </svg>
        </div>
        <div
          style={{
            height: '1px',
            strokeWidth: '1',
            stroke: 'red',
            boxSizing: 'border-box',
            position: 'absolute',
            left: '0.5px',
            right: '0.5px',
            top: '-0.5px',
          }}
        >
          <svg
            preserveAspectRatio="none"
            width="311"
            height="2"
            viewBox="0 0 311 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{width: '100%', height: '100%'}}
          >
            <path d="M0.5 1H310.5" stroke="#C6C6C6" strokeMiterlimit="16" strokeDasharray="1 2"></path>
          </svg>
        </div>
        <div
          style={{
            height: '1px',
            strokeWidth: '1',
            stroke: 'red',
            boxSizing: 'border-box',
            position: 'absolute',
            left: '0.5px',
            right: '0.5px',
            top: '79px',
          }}
        >
          <svg
            preserveAspectRatio="none"
            width="311"
            height="1"
            viewBox="0 0 311 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{width: '100%', height: '100%'}}
          >
            <path d="M0.5 0.5H310.5" stroke="#C6C6C6" strokeMiterlimit="16" strokeDasharray="1 2"></path>
          </svg>
        </div>
        <div
          style={{
            height: '1px',
            strokeWidth: '1',
            stroke: 'red',
            boxSizing: 'border-box',
            position: 'absolute',
            left: '0.5px',
            right: '0.5px',
            top: '39px',
          }}
        >
          <svg
            preserveAspectRatio="none"
            width="311"
            height="1"
            viewBox="0 0 311 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{width: '100%', height: '100%'}}
          >
            <path d="M0.5 0.5H310.5" stroke="#C6C6C6" strokeMiterlimit="16" strokeDasharray="1 2"></path>
          </svg>
        </div>
      </div>
      <div
        style={{
          height: '160px',
          flexShrink: 0,
          fill: 'black',
          boxSizing: 'border-box',
          position: 'absolute',
          left: '0px',
          right: '0px',
          top: '0px',
        }}
      >
        <svg
          preserveAspectRatio="none"
          width="311"
          height="160"
          viewBox="0 0 311 160"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{width: '100%', height: '100%'}}
        >
          <path opacity="0.15" d="M311 120C151.514 120 126 0 0 0V160H311V120Z" fill="black"></path>
        </svg>
      </div>
      <div
        style={{
          height: '123px',
          flexShrink: 0,
          strokeWidth: '3',
          stroke: 'black',
          boxSizing: 'border-box',
          position: 'absolute',
          left: '1px',
          right: '1px',
          top: '-1.5px',
        }}
      >
        <svg
          preserveAspectRatio="none"
          width="309"
          height="124"
          viewBox="0 0 309 124"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{width: '100%', height: '100%'}}
        >
          <path d="M0 2C126 2 149.514 122 309 122" stroke="black" strokeWidth="3"></path>
        </svg>
      </div>
      <div
        style={{
          color: 'black',
          textAlign: 'right',
          fontSize: '0.75rem',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '0.75rem',
          letterSpacing: '-0.096px',
          textTransform: 'uppercase',
          boxSizing: 'border-box',
          position: 'absolute',
          left: '12px',
          bottom: '12px',
          height: '12px',
        }}
      >
        Rise
      </div>
      <div
        style={{
          width: '24px',
          height: '24px',
          flexShrink: 0,
          boxSizing: 'border-box',
          position: 'absolute',
          right: '-6px',
          bottom: '28px',
        }}
      >
        <div
          style={{
            width: '24px',
            height: '24px',
            flexShrink: 0,
            boxSizing: 'border-box',
            position: 'absolute',
            left: 'calc(50% + 0px)',
            transform: 'translateX(-50%) translateY(-50%)',
            top: 'calc(50% + 0px)',
          }}
        >
          <svg
            preserveAspectRatio="none"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{width: '100%', height: '100%'}}
          >
            <circle cx="8" cy="8" r="8" transform="matrix(1 0 0 -1 4 20)" fill="black"></circle>
            <circle
              cx="8"
              cy="8"
              r="10"
              transform="matrix(1 0 0 -1 4 20)"
              stroke="black"
              strokeOpacity="0.2"
              strokeWidth="4"
            ></circle>
          </svg>
        </div>
        <div
          style={{
            width: '8px',
            height: '8px',
            flexShrink: 0,
            boxSizing: 'border-box',
            position: 'absolute',
            left: 'calc(50% + 0px)',
            transform: 'translateX(-50%) translateY(-150%)',
            top: 'calc(50% + 0px)',
          }}
        >
          <svg
            preserveAspectRatio="none"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{width: '100%', height: '100%'}}
          >
            <circle cx="4" cy="4" r="4" transform="matrix(1 0 0 -1 0 8)" fill="white"></circle>
          </svg>
        </div>
      </div>
      <div
        style={{
          width: '24px',
          height: '24px',
          flexShrink: 0,
          boxSizing: 'border-box',
          position: 'absolute',
          left: '-6px',
          top: '-12px',
        }}
      >
        <div
          style={{
            width: '24px',
            height: '24px',
            flexShrink: 0,
            boxSizing: 'border-box',
            position: 'absolute',
            left: 'calc(50% + 0px)',
            transform: 'translateX(-50%) translateY(-50%)',
            top: 'calc(50% + 0px)',
          }}
        >
          <svg
            preserveAspectRatio="none"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{width: '100%', height: '100%'}}
          >
            <circle cx="8" cy="8" r="8" transform="matrix(1 0 0 -1 4 20)" fill="black"></circle>
            <circle
              cx="8"
              cy="8"
              r="10"
              transform="matrix(1 0 0 -1 4 20)"
              stroke="black"
              strokeOpacity="0.2"
              strokeWidth="4"
            ></circle>
          </svg>
        </div>
        <div
          style={{
            width: '8px',
            height: '8px',
            flexShrink: 0,
            boxSizing: 'border-box',
            position: 'absolute',
            left: 'calc(50% + 0px)',
            transform: 'translateX(-50%) translateY(-150%)',
            top: 'calc(50% + 0px)',
          }}
        >
          <svg
            preserveAspectRatio="none"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{width: '100%', height: '100%'}}
          >
            <circle cx="4" cy="4" r="4" transform="matrix(1 0 0 -1 0 8)" fill="white"></circle>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default StaticSleepDebtChart;

import React from 'react';
import './interstitialBase.css';
import './RiseInterstitialTemplate.css';
import {RiseInterstitialProps} from './RiseInterstitialBase';
import RiseSurveyInterstitialControls from '../RiseSurveyControls/RiseSurveyInterstitialControls';

export type RiseInterstitialTemplateProps = {
  headerText?: string;
  bodyText?: string;
  heroImage?: React.ReactNode;
  secondaryImage?: React.ReactNode;
  continueButtonTitle?: string;
  onClick?: () => void;
} & RiseInterstitialProps;

const RiseInterstitialTemplate: React.FC<RiseInterstitialTemplateProps> = ({
  headerText,
  bodyText,
  heroImage,
  secondaryImage,
  continueButtonTitle,
  onClick,
}) => {
  return (
    <div id="dynamic_template_1">
      {headerText ? (
        <div className="headerText">
          <div className="heading1" dangerouslySetInnerHTML={{__html: headerText.replaceAll(/[\s\u200B]+/g, ' ')}} />
        </div>
      ) : null}
      {heroImage ? <div className="heroImage">{heroImage}</div> : null}
      {bodyText ? (
        <div className="bodyText">
          <div dangerouslySetInnerHTML={{__html: bodyText.replaceAll(/\s+/g, ' ')}} />
        </div>
      ) : null}
      {secondaryImage ? <div className="secondaryImage">{secondaryImage}</div> : null}
      <RiseSurveyInterstitialControls continueButtonTitle={continueButtonTitle} onClick={onClick} />
    </div>
  );
};
export default RiseInterstitialTemplate;

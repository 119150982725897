import {GrowthBookProvider} from '@growthbook/growthbook-react';
import {PayPalScriptProvider} from '@paypal/react-paypal-js';
import * as Sentry from '@sentry/react';
import Routes from 'appRoutes/AppRoutes';
import 'index.css';
import ReactDOM from 'react-dom/client';
import ReactPixel from 'react-facebook-pixel';
import {BrowserRouter} from 'react-router-dom';
import 'riseStyle.css';
import Environment from 'utils/Environment';
import {growthBook, setupGrowthbook} from 'utils/growthbook';
import {getPayPalCurrency} from 'utils/locale';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  environment: Environment.isProduction ? 'production' : 'test',
  dsn: 'https://e9e809f7580fb64ecb1d02c5d4f540a5@o107506.ingest.us.sentry.io/4507862165553152',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: Environment.isProduction ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('689199994939883', undefined, options);

ReactPixel.pageView(); // For tracking page view

const payPalOptions = {
  clientId: 'ATOpBcQQXFZHgIamIabQJYQLF4J7CW3XGMeBFMpMNOEVQ4-9yfX_kTLSMGdu92yDYfrXqhJ5NS62wPJG',
  currency: getPayPalCurrency(),
  intent: 'capture',
  vault: true,
};

setupGrowthbook();

const AppRoot = () => {
  return (
    <GrowthBookProvider growthbook={growthBook}>
      <PayPalScriptProvider options={payPalOptions}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </PayPalScriptProvider>
    </GrowthBookProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppRoot />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const riseSurveyTheme = {
  root: 'sd-root-modern rise-root',
  body: 'sd-body rise-body',
  bodyNavigationButton: 'sd-btn rise-btn',
  container: 'sd-container-modern rise-container',
  navigation: {
    complete: 'sd-btn--action sd-navigation__complete-btn',
    prev: 'sd-navigation__prev-btn',
    next: '',
    start: 'sd-navigation__start-btn',
    preview: 'sd-navigation__preview-btn',
    edit: 'sd-btn sd-btn--small',
  },
  actionBar: {
    root: 'sd-action-bar rise-action-bar',
    item: 'sd-action rise-action',
    defaultSizeMode: '',
    smallSizeMode: '',
    itemPressed: 'sd-action--pressed',
    itemAsIcon: 'sd-action--icon',
    itemIcon: 'sd-action__icon',
    itemTitle: 'sd-action__title',
  },
  question: {
    title: ' rise-title',
    // title: 'sd-title sd-element__title sd-question__title rise-title',
    content: 'question-content',
    answered: 'question-answered',
    titleRequired: 'question-title-required',
  },
  radiogroup: {
    root: 'sd-selectbase rise-radio-root',
    rootMobile: 'sd-selectbase--mobile',
    rootRow: 'sd-selectbase--row',
    rootMultiColumn: 'sd-selectbase--multi-column',
    item: 'sd-item sd-radio sd-selectbase__item rise-radio-item',
    itemOnError: 'sd-item--error',
    itemInline: 'sd-selectbase__item--inline',
    label: 'sd-selectbase__label rise-radio-label',
    labelChecked: '',
    itemDisabled: 'sd-item--disabled sd-radio--disabled',
    itemReadOnly: 'sd-item--readonly sd-radio--readonly',
    itemPreview: 'sd-item--preview sd-radio--preview',
    itemPreviewSvgIconId: '#icon-v2check',
    itemChecked: 'sd-item--checked sd-radio--checked rise-radio-checked',
    itemHover: 'sd-item--allowhover sd-radio--allowhover',
    itemControl: 'sd-visuallyhidden sd-item__control sd-radio__control',
    itemDecorator: 'sd-item__svg sd-radio__svg',
    controlLabel: 'sd-item__control-label rise-radio-control-label',
    materialDecorator: 'sd-item__decorator sd-radio__decorator rise-radio-decorator',
    other: 'sd-input sd-comment sd-selectbase__other',
    clearButton: '',
    column: 'sd-selectbase__column',
  },
};

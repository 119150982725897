import styles from './SavedDiscountBanner.module.css';
import stopwatch_orange from './assets/stopwatch-orange.svg';
import {DiscountButton} from '../CheckoutButtons/CheckoutButtons';
import {useOfferExpirationTimer} from '../OfferExpirationTimer/OfferExpirationTimer';

export const SavedDiscountBanner = ({onButtonClick, show}: {show: boolean; onButtonClick: () => void}) => {
  const {timedOut, duration} = useOfferExpirationTimer();

  return (
    <div className={`${styles.container} ${show ? styles.show : styles.hide}`}>
      <section className={styles.banner}>
        {timedOut ? (
          <div className={styles.bannerContent}>
            <img src={stopwatch_orange} alt="Stopwatch" />
            <div>We saved your discount!</div>
          </div>
        ) : (
          <div className={styles.bannerContent}>
            <img src={stopwatch_orange} alt="Stopwatch" />
            <div>
              Discount available
              <br />
              <strong>{duration}</strong>
            </div>
          </div>
        )}
        <div>
          <DiscountButton title="Get discount" onClick={onButtonClick} />
        </div>
      </section>
    </div>
  );
};

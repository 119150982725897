import React, {useCallback, useEffect, useRef, useState} from 'react';
import './CheckoutScreen.css';
import chart from './assets/productivity_chart.png';
import checkmark from './assets/checkmark.svg';
import stopwatch from './assets/stopwatch.svg';
import stopwatch_orange from './assets/stopwatch-orange.svg';
import star from './assets/star.svg';
import lock from './assets/lock.svg';
import purchase_lock from './assets/purchase_lock.svg';
import card_discover from './assets/card_discover.webp';
import card_mastercard from './assets/card_master.webp';
import card_visa from './assets/card_visa.webp';
import card_amex from './assets/card_amex.webp';
import editors_choice from './assets/editors_choice.png';
import community from './assets/community-map.webp';
import map from './assets/map.svg';
import checkout_logos from './assets/checkout-logos.webp';
import stripe_logo from './assets/stripe.png';
import mastercard_logo from './assets/mastercard_id_check.png';
import visa_logo from './assets/visa_secure.png';
import ssl_logo from './assets/secure_ssl_encryption.png';
import {Duration, intervalToDuration} from 'date-fns';
import feat_buzzfeed from './assets/1.webp';
import feat_forbes from './assets/4.webp';
import feat_goodhousekeeping from './assets/5.webp';
import feat_lifewire from './assets/6.webp';
import feat_techcrunch from './assets/7.webp';
import feat_nyt from './assets/8.webp';
import feat_today from './assets/9.webp';
import right_arrow from './assets/right_arrow.svg';
import thumbs_up from './assets/thumbs_up.svg';
import person_1 from './assets/person-1.webp';
import person_2 from './assets/person-2.webp';
import person_3 from './assets/person-3.webp';
import star_bulletpoint from './assets/star-bulletpoint.svg';
import loading_spinner from './assets/loading_spinner.svg';

import {
  Stripe,
  StripeElementsOptions,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  StripeElements,
} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer, PaymentRequestButtonElement} from '@stripe/react-stripe-js';
import {monthPlan, PlanInfo, quarterPlan, weekPlan} from '../../utils/stripePlans';
import {useSessionStorage} from 'usehooks-ts';
import {useNavigate} from 'react-router-dom';
import Analytics from '../../utils/Analytics';
import {getUserCountry} from '../../utils/locale';
import {useEffectOnce} from '../../utils/useEffectOnce';
import ReactPixel from 'react-facebook-pixel';
import {createAccount, createCheckoutDetails, payWithCreditCard, payWithPlatformPay} from '../../externalAPIs/hypnos';
import {createPaymentRequest, createStripeInstance} from '../../externalAPIs/stripe';
import {PayPalButtons} from '@paypal/react-paypal-js';
import {useCheckoutScreenAnalytics} from 'utils/useCheckoutScreenAnalytics';

type CheckoutScreenProps = {
  variantName: string;
};

export default function CheckoutScreen({variantName}: CheckoutScreenProps) {
  const {countryCode: country, userLanguages} = getUserCountry();

  const [showBanner, setShowBanner] = useState(false);
  const timerDuration = 5 * 60 * 1000;
  const [timedOut, setTimedOut] = useState(false);
  const [timeLeft, setTimeLeft] = useState(timerDuration);

  const [subscription, setSubscription] = useSessionStorage('subscription', '');
  const [planToPurchase, setPlanToPurchase] = useState<PlanInfo>(monthPlan[country]);

  const paymentRef = useRef<HTMLDivElement>(null);

  useEffectOnce(() => {
    Analytics.track('Country Code Determined', {country, userLanguages});
  });

  useCheckoutScreenAnalytics(variantName);

  useEffect(() => {
    Analytics.track('Plan Selected', {
      ...planToPurchase,
    });
  }, [planToPurchase]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowBanner(true);
      } else {
        setShowBanner(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft <= 1000) {
          setTimedOut(true);
          clearInterval(interval);
          return 0;
        }
        return prevTimeLeft - 1000;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatDuration = (duration: Duration) => {
    const minutes = duration.minutes?.toString().padStart(2, '0') ?? '00';
    const seconds = duration.seconds?.toString().padStart(2, '0') ?? '00';
    return `${minutes}:${seconds}`;
  };

  if (subscription) {
    return <AccountCreation />;
  }

  const onGetMyPlanClick = () => {
    Analytics.track('Get My Plan Clicked');
    window.scrollTo({top: paymentRef.current?.offsetTop, behavior: 'smooth'});
  };

  return (
    <div className="checkout container" data-testid="screen-checkout">
      <Header />
      <MainOffer />
      <PricingOptions timeLeft={timeLeft} timedOut={timedOut} formatDuration={formatDuration} />
      <PlanSelection
        selectedPlan={planToPurchase}
        onPriceSelection={plan => setPlanToPurchase(plan)}
        onButtonClick={onGetMyPlanClick}
      />
      <EditorsChoice />
      <SafeCheckout />
      <Testimonials />
      <BlackButton title="Get my plan" onClick={onGetMyPlanClick} />
      <CommunityInvitation />
      <MoneyBackGuarantee />
      <RiseFeatured />
      <RisePromise onButtonClick={onGetMyPlanClick} />
      <YouGetFeatures selectedPlan={planToPurchase} />
      <div ref={paymentRef} style={{marginBottom: '10rem'}}>
        <PaymentElement
          onSuccess={(subscription: string) => setSubscription(subscription)}
          planToPurchase={planToPurchase}
        />
      </div>
      <div className={`saved-discount-banner-container ${showBanner ? 'show' : 'hide'}`}>
        <SavedDiscountBanner onButtonClick={onGetMyPlanClick} />
      </div>
    </div>
  );
}

const SavedDiscountBanner = ({onButtonClick}: {onButtonClick: () => void}) => (
  <section className="saved-discount-banner">
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '8px', paddingRight: '12px'}}>
      <img src={stopwatch_orange} alt="Stopwatch" />
      <div>We saved your discount!</div>
    </div>
    <div>
      <DiscountButton title="Get discount" onClick={onButtonClick} />
    </div>
  </section>
);

const Header = () => (
  <header className="header">
    <img src={chart} alt="Productivity Chart" className="image" />
  </header>
);

const MainOffer = () => (
  <section className="main-offer">
    <h2>Your Personalized Plan is Ready</h2>
    <div className="offer-items">
      <div className="offer-item">
        <img src={checkmark} alt="Checkmark" />
        <div>Optimized sleep insights for you, balancing life & rest</div>
      </div>
      <div className="offer-item">
        <img src={checkmark} alt="Checkmark" />
        <div>Strategies to fall asleep faster, aiming for morning freshness</div>
      </div>
      <div className="offer-item">
        <img src={checkmark} alt="Checkmark" />
        <div>Personalized bedtime routine for deeper, uninterrupted sleep</div>
      </div>
    </div>
  </section>
);

const PricingOptions = ({
  timeLeft,
  timedOut,
  formatDuration,
}: {
  timeLeft: number;
  timedOut: boolean;
  formatDuration: (duration: Duration) => string;
}) => (
  <section className="pricing">
    <h3>Choose your plan</h3>
    <OfferExpirationTimer timeLeft={timeLeft} timedOut={timedOut} formatDuration={formatDuration} />
  </section>
);

const Testimonials = () => (
  <section className="testimonials" style={{marginTop: '24px'}}>
    <h3>Transforming Sleep, Boosting Energy</h3>
    <div className="testimonial">
      <div className={'stars'}>
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
      </div>
      <cite> Jamie T.</cite>
      "Since I started using Rise, my mornings have changed completely. I used to struggle with falling asleep and felt
      like I needed a crane just to get out of bed. Now, I wake up feeling genuinely refreshed. The app's personalized
      tips, especially for someone in their 30s struggling with sleep, have been a game-changer. It's like having a
      sleep coach in my pocket."
    </div>
    <div className="testimonial">
      <div className={'stars'}>
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
      </div>
      <cite>Chris P.</cite>
      "Rise has helped me understand my sleep needs and how to meet them. I've always had a hard time falling asleep,
      but the app's recommendations have made a significant difference. I appreciate how it tailors advice based on my
      age group and my goal to wake up feeling refreshed. Plus, tracking my sleep debt has been eye-opening!"
    </div>
    <div className="testimonial">
      <div className={'stars'}>
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
        <img src={star} alt="*" />
      </div>
      <cite>Morgan L.</cite>
      "I was skeptical at first, but Rise has transformed my sleep habits. The circadian rhythm optimization is
      fascinating and effective. I've adjusted my bedtime and wake-up time slightly, following the app's guidance, and
      even my weekends have improved. The fact that it considers my dinner habits and bedroom environment makes the
      advice feel very personalized."
    </div>
  </section>
);

const CommunityInvitation = () => (
  <section className="community">
    <h2>Join Rise community</h2>
    <p style={{fontSize: '17px'}}>
      More than <strong>5 million+</strong> happy users!
    </p>
    <div className="map-container">
      <img src={map} alt="Community" className="image" />
      <div className="peoples-heads">
        <img src={community} alt="Community" className="image" />
      </div>
    </div>
  </section>
);

const MoneyBackGuarantee = () => (
  <section className="guarantee">
    <h3>100% money back guarantee</h3>
    <p>
      Elevate your well-being with Rise App—a comprehensive tracker designed to enhance your sleep quality and daily
      energy levels. We're confident in the transformative impact our app can have on your overall wellness. If, within
      30 days of purchase, you find that Rise doesn't meet your expectations, we're pleased to offer a full refund. Your
      satisfaction in achieving better sleep and heightened energy is our priority."
    </p>
  </section>
);

const RiseFeatured = () => {
  return (
    <section className="rise-features">
      <h3>Rise has been featured in</h3>
      <div className="carousel-container">
        <div className="carousel">
          <div className="carousel-item">
            <img src={feat_nyt} alt="NYT" />
          </div>
          <div className="carousel-item">
            <img src={feat_buzzfeed} alt="Buzzfeed" />
          </div>
          <div className="carousel-item">
            <img src={feat_forbes} alt="Forbes" />
          </div>
          <div className="carousel-item">
            <img src={feat_goodhousekeeping} alt="Good Housekeeping" />
          </div>
          <div className="carousel-item">
            <img src={feat_lifewire} alt="Lifewire" />
          </div>
          <div className="carousel-item">
            <img src={feat_techcrunch} alt="TechCrunch" />
          </div>

          <div className="carousel-item">
            <img src={feat_today} alt="Today" />
          </div>
        </div>
      </div>
    </section>
  );
};

const PlanOption = ({
  index,
  selectedPlan,
  plan,
  onPriceSelection,
}: {
  index: number;
  selectedPlan: PlanInfo;
  plan: PlanInfo;
  onPriceSelection: (plan: PlanInfo) => void;
}) => {
  const selected = selectedPlan.id === plan.id;

  return (
    <div className={`plan-selection ${selected ? 'selected' : ''}`} onClick={() => onPriceSelection(plan)}>
      <div className={`plan-selection-header ${selected ? 'selected' : 'unselected'}`}>
        {index === 1 ? 'LITE VERSION' : null}
        {index === 2 ? (
          <>
            <div className="plan-selection-header-left">
              <span>MOST POPULAR</span>
              <img src={thumbs_up} alt="Thumbs Up" />
            </div>
            <div>
              <div className="plan-selection-header-right">-{`${plan.percentOff}`}%</div>
            </div>
          </>
        ) : null}
        {index === 3 ? (
          <>
            <div className="plan-selection-header-left">
              <span>BEST PRICE</span>
            </div>
            <div>
              <div className="plan-selection-header-right">-{`${plan.percentOff}`}%</div>
            </div>
          </>
        ) : null}
      </div>
      <div className="plan-selection-price">
        <div className="plan-selection-header-price-info">
          <div className="plan-selection-duration">{plan.frequency}</div>
          <div className="strikethrough-container gray">
            <s>{`${plan.label}${plan.price}`}</s>
            <img src={right_arrow} alt="Right Arrow" />
            <span>{`${plan.label}${plan.introPrice}`}</span>
          </div>
        </div>
        <div className="plan-selection-per-day">
          <div className="plan-selection-dollar">{`${plan.label}${plan.perDay === 1 ? plan.perDay : plan.perDay.toFixed(2)}`}</div>
          <div className="plan-selection-day-info">per day</div>
        </div>
      </div>
    </div>
  );
};

const PlanSelection = ({
  selectedPlan,
  onPriceSelection,
  onButtonClick,
}: {
  selectedPlan: PlanInfo;
  onPriceSelection: (plan: PlanInfo) => void;
  onButtonClick: () => void;
}) => {
  const {countryCode: locale} = getUserCountry();
  const weeklyPlan = weekPlan[locale];
  const monthlyPlan = monthPlan[locale];
  const quarterlyPlan = quarterPlan[locale];

  return (
    <section className="">
      <div className="plan-selection-container">
        <PlanOption index={1} selectedPlan={selectedPlan} plan={weeklyPlan} onPriceSelection={onPriceSelection} />
        <PlanOption index={2} selectedPlan={selectedPlan} plan={monthlyPlan} onPriceSelection={onPriceSelection} />
        <PlanOption index={3} selectedPlan={selectedPlan} plan={quarterlyPlan} onPriceSelection={onPriceSelection} />
        <div className="plan-selection-button">
          <BlackButton title="Get my plan" onClick={onButtonClick} />
          <p className="plan-selection-guarantee">100% Money-back guarantee</p>
        </div>
      </div>
    </section>
  );
};

const PaymentElement = ({
  onSuccess,
  planToPurchase,
}: {
  onSuccess: (subscription: string) => void;
  planToPurchase: PlanInfo;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [clientSecret, setClientSecret] = useState<string | undefined>('');
  const [stripeInstance, setStripeInstance] = useState<Stripe | null>(null);
  const [options, setOptions] = useState<StripeElementsOptions | null>(null);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>(undefined);

  const loadingStripeRef = useRef<boolean>(false);

  const handlePurchase = useCallback(
    async (purchaseMethod: () => Promise<{subscription: string | null; error: string | null}>) => {
      setError('');
      setLoading(true);
      const {subscription, error} = await purchaseMethod();
      if (subscription) {
        onSuccess(subscription);
      } else {
        setError(error ?? 'Could not complete checkout. Please try again or use an alternate method.');
      }
      setLoading(false);
    },
    [onSuccess, setError, setLoading],
  );

  const purchaseWithCreditCard = async (elements: StripeElements | null) => {
    await handlePurchase(async () => await payWithCreditCard(planToPurchase, elements, stripeInstance, clientSecret));
  };

  // Apple Pay Handler: If user can use Apple/Google Pay, set up the payment request handler
  useEffect(() => {
    const handler = async (event: PaymentRequestPaymentMethodEvent) => {
      await handlePurchase(async () => {
        const {subscription, error} = await payWithPlatformPay(planToPurchase, event);
        if (subscription) {
          event.complete('success');
        } else {
          event.complete('fail');
        }
        return {subscription, error};
      });
    };

    // Listen for event to handle Apple/Google Pay
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', handler);
    }

    return () => {
      // Unsubscribe from the event when the useEffect is re-rendered
      if (paymentRequest) {
        paymentRequest.off('paymentmethod', handler);
      }
    };
  }, [planToPurchase, paymentRequest, handlePurchase]);

  useEffect(() => {
    const setupStripe = async () => {
      if (!stripeInstance && !loadingStripeRef.current) {
        loadingStripeRef.current = true;
        const {client_secret} = await createCheckoutDetails();
        setClientSecret(client_secret);
        setOptions({
          clientSecret: client_secret,
          appearance: {
            variables: {
              colorPrimary: '#0570de',
              colorBackground: '#30313d',
              colorText: 'white',
              colorDanger: '#df1b41',
              borderRadius: '4px',
              colorPrimaryText: 'white',
              fontSizeSm: '1rem',
            },
          },
        });
        const stripe = await createStripeInstance();
        if (stripe) {
          setStripeInstance(stripe);
        }
        loadingStripeRef.current = false;
      }
    };

    setupStripe();
  }, [stripeInstance, loadingStripeRef]);

  useEffect(() => {
    if (stripeInstance) {
      if (paymentRequest) {
        paymentRequest.update({
          total: {
            label: planToPurchase.frequency,
            amount: planToPurchase.purchasePrice,
          },
        });
      } else {
        createPaymentRequest(stripeInstance, planToPurchase).then(paymentRequest => {
          // likely reason to be undefined is that Apple Pay or Google Pay are not supported
          setPaymentRequest(paymentRequest);
        });
      }
    }
  }, [planToPurchase, paymentRequest, setPaymentRequest, stripeInstance]);

  if (!stripeInstance || !options) {
    return null;
  }

  const plan_id = planToPurchase.payPalPlanId;

  return (
    <Elements stripe={stripeInstance} options={options}>
      <ElementsConsumer>
        {({elements}) => (
          <section>
            {loading ? <LoadingSpinner /> : null}
            <h3 className="payment-details-container">Payment method</h3>
            <p className="payment-details">Rise will use your payment details for seamless future payments.</p>
            {error ? <p style={{color: 'red'}}>{error}</p> : null}
            <div className="checkout-container">
              {stripeInstance && paymentRequest ? (
                <div className="mt-4">
                  <PaymentRequestButtonElement
                    options={{
                      style: {
                        paymentRequestButton: {
                          theme: 'dark',
                          height: '55px',
                        },
                      },
                      paymentRequest,
                    }}
                    onClick={() => {
                      Analytics.track('Platform Pay Clicked');
                    }}
                  />
                </div>
              ) : null}
              <div className="paypal">
                <PayPalButtons
                  style={{
                    layout: 'horizontal',
                    color: 'gold',
                    tagline: false,
                    height: 55,
                  }}
                  forceReRender={[plan_id]}
                  onApprove={async data => {
                    if (data.subscriptionID) {
                      onSuccess(data.subscriptionID);
                      Analytics.track('PayPal Checkout Success', {
                        subscription: data.subscriptionID,
                        ...planToPurchase,
                      });
                      Analytics.track('PaymentAccepted');
                      ReactPixel.track('Purchase', {
                        currency: planToPurchase.currency.toUpperCase(),
                        value: planToPurchase.introPrice,
                      });
                    } else {
                      setError('Could not complete PayPal Checkout. Please try again or use an alternate method.');
                      Analytics.track('PayPal Checkout Error', {message: 'Did not return subscription id', ...data});
                    }
                  }}
                  createSubscription={async (data, actions) => {
                    Analytics.track('PayPal Checkout Created', data);
                    return actions.subscription.create({
                      plan_id: planToPurchase.payPalPlanId,
                    });
                  }}
                  onInit={() => {
                    Analytics.track('PayPal Buttons Shown');
                  }}
                  onClick={data => {
                    setError('');
                    Analytics.track('PayPal Button Clicked', data);
                  }}
                  onCancel={data => {
                    Analytics.track('PayPal Checkout Cancelled', data);
                  }}
                  onError={err => {
                    setError('Could not complete PayPal Checkout. Please try again or use an alternate method.');
                    Analytics.track('PayPal Checkout Error', err);
                  }}
                />
              </div>
              <div className="credit-card">
                <div className="card-options">
                  <div className="credit-card-title">Credit card</div>
                  <div style={{height: '1.5rem'}}>
                    <img width="150" className="payment-logo" src={checkout_logos} alt="Card logos" />
                  </div>
                </div>
                <p className="card-details">Card details</p>
                <form
                  onSubmit={async event => {
                    event.preventDefault();
                    await purchaseWithCreditCard(elements);
                  }}
                >
                  <div
                    style={{
                      border: '1px solid #D3D3D3',
                      borderRadius: '2px',
                      padding: '1.5px',
                      paddingLeft: '12px',
                      marginBottom: '1rem',
                      paddingTop: '12px',
                      paddingBottom: '12px',
                    }}
                  >
                    <CardElement
                      options={{
                        style: {
                          base: {
                            backgroundColor: 'white',
                          },
                        },
                      }}
                      onFocus={() => {
                        Analytics.track('Credit Card Form Focused');
                      }}
                    />
                  </div>
                  <button id="submit" className="black-button">
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <div className="confirm-purchase-button">
                        <img src={purchase_lock} alt=" Purchase Lock" />
                        <span>Confirm Purchase</span>
                      </div>
                    </div>
                  </button>
                </form>
                <div className="encryption">
                  <div className="encryption-details">Your payment information is secure with SSL/TLS encryption</div>
                  <div className="payment-security-image-container">
                    <div className="payment-security-image">
                      <img className="payment-logo" src={ssl_logo} alt="Secure SSL Encryption" />
                    </div>
                    <div className="payment-security-image">
                      <img className="payment-logo" src={visa_logo} alt="Visa Secure" />
                    </div>
                    <div className="payment-security-image">
                      <img className="payment-logo" src={stripe_logo} alt="Powered By Stripe" />
                    </div>
                    <div className="payment-security-image">
                      <img className="payment-logo" src={mastercard_logo} alt="Mastercard ID Check" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </ElementsConsumer>
    </Elements>
  );
};

const OfferExpirationTimer = ({
  timeLeft,
  timedOut,
  formatDuration,
}: {
  timeLeft: number;
  timedOut: boolean;
  formatDuration: (duration: Duration) => string;
}) => {
  const duration = intervalToDuration({start: 0, end: timeLeft});
  return (
    <div className="offer-expiration-timer">
      <img src={stopwatch} alt="Stopwatch" />
      <div>{timedOut ? 'We saved your discount!' : `This offer expires in ${formatDuration(duration)}`}</div>
    </div>
  );
};

const EditorsChoice = () => (
  <section className="editors-choice">
    <img src={editors_choice} alt="Editors' Choice" className="image" />
  </section>
);
const SafeCheckout = () => (
  <section className="secure-checkout" style={{marginTop: '48px'}}>
    <h3>Guaranteed safe checkout</h3>
    <div className="cards">
      <div className="card">
        <img src={card_visa} alt="Visa" className="image" />
      </div>
      <div className="card">
        <img src={card_mastercard} alt="Mastercard" className="image" />
      </div>
      <div className="card">
        <img src={card_amex} alt="Amex" className="image" />
      </div>
      <div className="card">
        <img src={card_discover} alt="Discover" className="image" />
      </div>
    </div>
    <div className="subtitle">
      <img src={lock} alt="Lock" />
      <p>All transactions are secure and encrypted</p>
    </div>
  </section>
);

const RisePromise = ({onButtonClick}: {onButtonClick: () => void}) => {
  return (
    <section className="rise-promise" style={{marginTop: '48px'}}>
      <h3>Our Promise</h3>
      <div>
        Elevate your sleep with Rise, the advanced sleep tracker designed to help you understand and improve your sleep
        patterns. We understand the importance of quality rest, and Rise is here to provide you with the insights and
        tools you need for better sleep. With Rise, you can effortlessly track your sleep cycles, monitor sleep quality,
        and receive personalized recommendations to optimize your sleep routine. Our app utilizes cutting-edge
        technology to empower you to make informed decisions about your sleep habits. Join a community of individuals
        prioritizing their sleep health and well-being. Rise isn't just a sleep tracker; it's your partner in achieving
        restful nights and energized days.
        <br />
        <br />
      </div>

      <BlackButton title="Get my plan" onClick={onButtonClick} />
    </section>
  );
};

const YouGetFeatures = ({selectedPlan}: {selectedPlan: PlanInfo}) => {
  return (
    <section className="you-get-features" style={{marginTop: '48px'}}>
      <ul>
        <li>
          You get a {selectedPlan.percentOff}% introductory offer discount – {selectedPlan.label}
          {Math.round(selectedPlan.price - selectedPlan.introPrice)}.
        </li>
        <li>
          Your introductory period will last for {selectedPlan.introDuration} for {selectedPlan.label}
          {selectedPlan.introPrice}.
        </li>
        <li>
          You can cancel anytime before the end on introductory period, and you will not be charged for membership.
        </li>
        <li>
          After the end of introductory period Rise will automatically continue your membership and charge{' '}
          {selectedPlan.label}
          {selectedPlan.price} / {selectedPlan.duration} until you cancel.
        </li>
        <li>You can cancel any time.</li>
      </ul>
    </section>
  );
};

const AccountCreation = () => {
  const [subscription] = useSessionStorage('subscription', '');
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const signUp = async (event: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.target as HTMLFormElement);
    const email = Object.fromEntries(formData.entries()).email as string;
    if (!email) {
      setError('Please enter your email address');
      return;
    }
    setError('');
    setLoading(true);
    const {person_id} = await createAccount(email, subscription);
    if (person_id) {
      Analytics.identify(person_id, {email});
      navigate(`/account-ready?email=${encodeURIComponent(email)}`);
    } else {
      setError('Could not register your account. Please try again.');
    }
    setLoading(false);
  };

  return (
    <div className="account-creation-container" data-testid="screen-account-creation">
      {loading ? <LoadingSpinner /> : null}
      <div>
        <form
          className="account-creation-form"
          onSubmit={async event => {
            event.preventDefault();
            await signUp(event);
          }}
        >
          <div>
            <h2>Thank you for your purchase! Let's create your account!</h2>
            <div className="paragraph centered">Enter the email where you'd like us to grant access.</div>
          </div>
          <div className="email-input-container">
            <div className="email-input-container-2">
              <label htmlFor="email" className="email-label">
                Email
              </label>
              <input id="email" type="email" className="email-input" placeholder="Email" name="email" />
            </div>

            <button className="email-submit">Submit</button>
            {error ? (
              <p data-testid="account-creation-error" style={{color: 'red'}}>
                {error}
              </p>
            ) : null}
          </div>
          <div className="users-container">
            <div className="person-container">
              <div className="image-container">
                <img className="person" src={person_1} alt="Person 1" />
              </div>
              <div className="image-container shift-left">
                <img className="person" src={person_2} alt="Person 2" />
              </div>
              <div className="image-container shift-left">
                <img className="person" src={person_3} alt="Person 3" />
              </div>
            </div>
            <div className="users">
              <span className="users-number">2,534,354</span> <span>users sleep better with our tracker!</span>
            </div>
          </div>
          <div className="bullets">
            <div className="bullet-point">
              <img src={star_bulletpoint} alt="Bulletpoint" />
              <span className="paragraph">Boost your daily energy</span>
            </div>
            <div className="bullet-point">
              <img src={star_bulletpoint} alt="Bulletpoint" />
              <span className="paragraph">Customized Sleep Improvement Plans</span>
            </div>
            <div className="bullet-point">
              <img src={star_bulletpoint} alt="Bulletpoint" />
              <span className="paragraph">Smart Sleep Analysis</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const BlackButton = ({title, onClick}: {title: string; onClick?: () => void}) => (
  <button className="black-button" onClick={() => (onClick ? onClick() : null)}>
    {title}
  </button>
);
const DiscountButton = ({title, onClick}: {title: string; onClick?: () => void}) => (
  <button className="discount-button" onClick={() => (onClick ? onClick() : null)}>
    {title}
  </button>
);

const LoadingSpinner = () => (
  <div className="loading">
    <div>
      <img className={'checkout-spinner'} src={loading_spinner} alt="" />
      <span className="screen-reader-only">Loading...</span>
    </div>
  </div>
);

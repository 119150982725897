import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Analytics from '../utils/Analytics';

export function usePageTracking() {
  const location = useLocation();
  //TODO: This is getting called twice on every page loud that is a route change. Hoepfully we can fix?
  useEffect(() => {
    Analytics.track(`Page ${location.pathname} viewed`, {
      path: location.pathname,
      search: location.search,
      hash: location.hash,
    });
  }, [location]);
}

import {PlanInfo} from 'utils/stripePlans';
import styles from './PlanDetails.module.css';
import common from 'common.module.css';

export const PlanDetails = ({selectedPlan}: {selectedPlan: PlanInfo}) => {
  const introPrice = selectedPlan.label + selectedPlan.introPrice;
  const price = selectedPlan.label + selectedPlan.price;
  return (
    <section className={styles.container}>
      {selectedPlan.product === 'web_acquisition_quarter' ? (
        <ul className={[common.colorGray2, common.paragraph].join(' ')}>
          <li>{introPrice} for the first quarter</li>
          <li>Renews at {price}/quarter after 3 months</li>
          <li>Cancel anytime</li>
        </ul>
      ) : selectedPlan.product === 'web_acquisition_week' ? (
        <ul className={[common.colorGray2, common.paragraph].join(' ')}>
          <li>{introPrice} for the first week</li>
          <li>Renews at {price}/month after first week</li>
          <li>Cancel anytime</li>
        </ul>
      ) : (
        <ul className={[common.colorGray2, common.paragraph].join(' ')}>
          <li>{introPrice} for the first month</li>
          <li>Renews at {price}/month after first month</li>
          <li>Cancel anytime</li>
        </ul>
      )}
    </section>
  );
};

import {SurveyModel} from 'survey-core';

const STORAGE_ITEM_KEY = 'rise-survey-data';

function clearSurveyData() {
  window.localStorage.removeItem(STORAGE_ITEM_KEY);
}

function saveSurveyData(survey: SurveyModel) {
  const data = survey.data;
  data.pageNo = survey.currentPageNo;
  data.completed = survey.state === 'completed';
  window.localStorage.setItem(STORAGE_ITEM_KEY, JSON.stringify(data));
}

function restoreSurveyData(survey: SurveyModel) {
  const prevData = window.localStorage.getItem(STORAGE_ITEM_KEY) ?? null;
  if (prevData) {
    const data = JSON.parse(prevData);
    survey.data = data;
    if (data.pageNo) {
      survey.currentPageNo = data.pageNo;
    }
    if (data.completed) {
      survey.completeLastPage();
    }
  }
}

function readSurveyData() {
  const data = window.localStorage.getItem(STORAGE_ITEM_KEY) ?? null;
  if (data) {
    try {
      return JSON.parse(data);
    } catch (error) {
      console.error('Error reading survey data', error);
      return null;
    }
  }
}

export const SurveyData = {
  clear: clearSurveyData,
  save: saveSurveyData,
  restore: restoreSurveyData,
  read: readSurveyData,
};

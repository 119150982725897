import {useEffect} from 'react';
import Environment from '../../utils/Environment';
import {growthBook} from '../../utils/growthbook';

const LOCAL_STORAGE_KEY = 'qa_experiment_forced_features';

const forceFeature = (featureKey: string, variant: any) => {
  const forcedFeatures = growthBook.getForcedFeatures();
  forcedFeatures.set(featureKey, variant);
  growthBook.setForcedFeatures(forcedFeatures);
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(Object.fromEntries(forcedFeatures)));
};

const clearForcedFeatures = () => {
  growthBook.setForcedFeatures(new Map());
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

/* Hydrate the forced features from local storage only in test environment */
export const useExperimentQAHydration = () => {
  useEffect(() => {
    if (Environment.isTest) {
      const forcedFeatures = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (forcedFeatures) {
        const parsedForcedFeatures = forcedFeatures ? new Map(Object.entries(JSON.parse(forcedFeatures))) : new Map();
        growthBook.setForcedFeatures(parsedForcedFeatures);
      }
    }
  }, []);
};

export default {
  forceFeature,
  clearForcedFeatures,
};

// Documentation: https://surveyjs.io/form-library/documentation/design-survey/conditional-logic#supported-operators

// NOTE: SurveyJS does not support empty strings, null, or undefined in calculatedValues,
// so to work around this we can use a zero width space character to represent an empty string.
export const ZERO_WIDTH_SPACE = '\u200B';

type SwitchExpressionGenerator = (params: {
  switch: string;
  cases: {
    _default: string;
    [key: string]: string;
  };
}) => `switch(${string},${string})`;

export const switchExpression: SwitchExpressionGenerator = ({switch: _switchValue, cases}) => {
  const casesArray = Object.entries(cases).map(([key, option]) => [key, option]);
  const casesString = casesArray.map(([key, option]) => `['${key}', '${option}']`).join(',');
  return `switch(${_switchValue},[${casesString}])`;
};

/**
 * A convenience function that generates the `switch` expression for SurveyJS
 * The `switch` property is the value to switch on.
 * The `cases` property is an object where the key is the value to switch to, and the value is the string to return.
 * The `_default` property is the value to return if no other cases match.
 *
 * @example
 * switchExpression({switch: "{gender}", cases: {Male: "Men", Female: "Women", _default: "People"}})
 *
 * @returns
 * "switch({gender},['Male','Men'],['Female','Women'],['_default','People'])"
 */
export const surveySwitchFn = (params: any[]) => {
  if (!params) return null;
  if (!params[1]) return null;
  const value = params[0]?.toLowerCase() as string;
  const cases = params[1] as [string, string | undefined][];
  const casesObj = Object.fromEntries(cases.map(([key, option]) => [key?.toLowerCase(), option]));
  //if a default value is defined, use it otherwise, return a zero width space character to represent an empty string.
  const defaultValue = casesObj._default && casesObj._default?.length > 0 ? casesObj._default : ZERO_WIDTH_SPACE;
  const result = casesObj[value] ?? defaultValue;

  return result;
};

import {v4 as uuid} from 'uuid';
import Analytics from './Analytics';

const anonymousIdKey = 'web_funnel_anon_id';
let anonId: string | null = null;

function _getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts?.pop()?.split(';').shift();
  return null;
}

/**
 * Uses uuid v4 to generate a unique anonymous id for the user.
 * The id is stored in memory and in LocalStorage.
 * If the id is not found in memory or LocalStorage, it will be recovered from Segment.
 * If the id is not found in Segment, a new one is generated and stored in memory and LocalStorage.
 */
const ensureAnonymousID = () => {
  if (!anonId) {
    //try to get the id from localStorage
    const localStorageAnonId = localStorage.getItem(anonymousIdKey);

    //try to get the id from the Segment
    const segmentCookieAnonId = _getCookie('ajs_anonymous_id');
    const segmentLocalStorageAnonId = localStorage.getItem('ajs_anonymous_id');
    const segmentAnonId = segmentCookieAnonId ?? segmentLocalStorageAnonId;

    if (localStorageAnonId) {
      //try to recover the id from localStorage
      anonId = localStorageAnonId;
    } else if (segmentAnonId) {
      //try to recover the id from the segment cookie
      anonId = segmentAnonId;
      Analytics.track('Recovered anonymous id from Segment', {
        segmentCookieAnonId,
        segmentLocalStorageAnonId,
      });
    } else {
      //Create a new anon id
      anonId = uuid();
    }

    localStorage.setItem(anonymousIdKey, anonId);
    Analytics.segment.setAnonymousId(anonId);
  }
  if (!localStorage.getItem(anonymousIdKey)) {
    localStorage.setItem(anonymousIdKey, anonId);
  }
  return anonId;
};

const dev_clearAnonymousId = () => {
  localStorage.removeItem(anonymousIdKey);
};

const dev_readAnonymousIdFromAllSources = () => {
  const localStorageId = localStorage.getItem(anonymousIdKey);
  const sessionStorageId = sessionStorage.getItem(anonymousIdKey);
  const cookieId = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${anonymousIdKey}=`))
    ?.split('=')[1];

  return {
    riseLocalStorageId: localStorageId ?? 'N/A',
    riseSessionStorageId: sessionStorageId ?? 'N/A',
    riseCookieId: cookieId ?? 'N/A',
  };
};

export default {
  ensureAnonymousID,
  dev_clearAnonymousId,
  dev_readAnonymousIdFromAllSources,
};

import chart from 'images/sleep-debt-reduction-chart.png';
import checkmark from './assets/checkmark-circle-gradient.svg';
import styles from './Hero.module.css';
import common from 'common.module.css';
import {addWeeks, format} from 'date-fns';

import {SurveyData} from 'screens/RiseSurvey/utils/surveyData';

const goalMap = {
  'fall asleep faster': 'falling asleep faster',
  'stay asleep throughout the night': 'staying asleep all night',
  'improve sleep quality': 'improving your sleep quality',
  'wake up refreshed': 'waking up refreshed',
  'reduce sleep debt': 'reducing Sleep Debt',
  _default: 'improving your sleep',
};

const ageMap = {
  'under 18': 'in their teens',
  '18-23': 'in their late teens or early 20s',
  '24-29': 'in their 20s',
  '30-39': 'in their 30s',
  '40-49': 'in their 40s',
  '50-59': 'in their 50s',
  '60+': 'over 60',
  _default: '',
};

const genderMap = {
  male: 'men',
  female: 'women',
  _default: 'people',
};

const findInMap = (key: string, map: Record<string, string>) => {
  return map[key?.toLowerCase() as keyof typeof map] || map._default;
};

export const Hero = () => {
  const surveyData = SurveyData.read();
  const goal = findInMap(surveyData?.goal, goalMap);
  const age = findInMap(surveyData?.age, ageMap);
  const gender = findInMap(surveyData?.gender, genderMap);
  const noAgeOrGender = (!surveyData?.gender && !surveyData?.age) || (gender === 'people' && age === '');
  const goalText = `Strategies for ${goal}`;
  const sleepDebtText = `Customized suggestions to reduce Sleep Debt and wake refreshed`;
  const insightText = `Sleep insights designed for ${noAgeOrGender ? 'you' : `${gender} ${age}`}`;

  return (
    <section>
      <div className={common.heading1}>Your personal plan is ready</div>
      <div className={styles.hero}>
        <img src={chart} alt="Sleep Debt" />

        <div className={styles.xAxis}>
          <div className={common.heading5} style={{textAlign: 'left', marginLeft: '22px'}}>
            {format(new Date(), 'MMM d')}
          </div>
          <div className={common.heading5} style={{textAlign: 'right', marginRight: '18px'}}>
            {format(addWeeks(new Date(), 3), 'MMM d')}
          </div>
        </div>
      </div>

      <div className={styles.features}>
        <div className={styles.list}>
          <div className={styles.listItem}>
            <img src={checkmark} alt="Checkmark" className={styles.checkmark} />
            <div>{goalText}</div>
          </div>
          <div className={styles.listItem}>
            <img src={checkmark} alt="Checkmark" className={styles.checkmark} />
            <div>{sleepDebtText}</div>
          </div>
          <div className={styles.listItem}>
            <img src={checkmark} alt="Checkmark" className={styles.checkmark} />
            <div>{insightText}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

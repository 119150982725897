import React from 'react';
import './EarlyBirdModal.css';

type Props = {
  onEarlyBird: () => void;
  onNightOwl: () => void;
};

const EarlyBirdModal = ({onEarlyBird, onNightOwl}: Props) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Early bird or night owl</h2>
        <div className="modal-buttons">
          <button className="modal-button" onClick={onEarlyBird}>
            Early bird
          </button>
          <button className="modal-button" onClick={onNightOwl}>
            Night owl
          </button>
        </div>
      </div>
    </div>
  );
};

export default EarlyBirdModal;

import {RiseQuestion, RiseSurveyPage} from '../../../types';
import {getRandomDelay} from './getRandomDelay';

type PageOptions = {
  /** How long to show the spinner for before moving to the next page */
  delayDuration?: 'random' | number | undefined;
  /** If true, the page will be skipped if the visibleIf condition is not met */
  visibleIf?: string;
};

export const page = (question: RiseQuestion, options: PageOptions = {}): RiseSurveyPage => {
  return {
    elements: [question],
    visibleIf: options.visibleIf,
    riseProperties: {
      delayOnNext: options.delayDuration === 'random' ? getRandomDelay() : (options.delayDuration ?? 0),
    },
  };
};

type RadioOptions = Omit<RiseQuestion, 'type'> & {
  /** How long to show the spinner for before moving to the next page. `random` will show a random delay between 1 and 5 seconds. */
  delay?: 'random' | number | undefined;
  /** If true, the page will be skipped if the visibleIf condition is not met */
  visibleIf?: string;
};

export const radioPage = ({visibleIf, delay, ...radioOptions}: RadioOptions): RiseSurveyPage => {
  return page(
    {
      type: 'radiogroup',
      ...radioOptions,
    },
    {
      visibleIf,
      delayDuration: delay,
    },
  );
};

/**
 * Preloads images into memory to reduce apparent load times.
 * @param imageUrls
 *
 */
export function preloadImages(imageUrls: string[]) {
  imageUrls.forEach(url => {
    const img = new Image();
    img.src = url;
  });
}

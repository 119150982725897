import styles from './RisePromise.module.css';
import {BlackButton} from '../CheckoutButtons/CheckoutButtons';
import common from 'common.module.css';

export const RisePromise = ({onButtonClick}: {onButtonClick: () => void}) => {
  return (
    <section className={styles.container}>
      <div className={common.heading1}>Our promise</div>
      <div className={[common.center, common.heading3].join(' ')}>
        Effortlessly track your sleep, increase energy, and receive personalized recommendations to optimize your sleep
        routine. Our app utilizes cutting-edge technology to empower you to make informed decisions about your sleep
        habits.
      </div>
      <BlackButton title="Get my plan" onClick={onButtonClick} />
    </section>
  );
};
